import { CRYPTOS } from "../cryptoData";

import { getIndianNumber } from "../../../../utils/helper";

const Crypto = ({
  name,
  color,
  logo,
  code,
  timeIntervalData,
  bgColor,
  fontFamily,
  currency,
}) => {
  const width = window.innerWidth;
  const first = timeIntervalData && timeIntervalData[0];
  const second = timeIntervalData && timeIntervalData[1];
  const openFiveMinLater = (first && first[1]["1. open"]) || 0;
  const openFiveMinOlder = (second && second[1]["1. open"]) || 0;
  const percentage =
    ((Number(openFiveMinLater) - Number(openFiveMinOlder)) /
      Number(openFiveMinOlder)) *
      100 || 0;

  const formattedPrice = getIndianNumber(
    parseFloat(openFiveMinLater).toFixed(4)
  );

  return (
    <div
      className="w-100 d-flex justiy-content-center align-items-center p-3"
      style={{
        background: bgColor || `linear-gradient(to right, #8555c9, #c488e0)`,
        fontFamily: fontFamily,
      }}
    >
      <div className="w-100">
        <p
          className="m-0 p-0 d-flex align-items-center"
          style={{
            fontWeight: "700",
            fontSize: width / 15 + "px",
            color: color,
          }}
        >
          <img
            className="me-2"
            src={`/images/crypto/${logo}`}
            alt="logo"
            style={{ height: width / 12, width: width / 12 }}
          />{" "}
          <p>{code || ""}</p>
        </p>
        <p
          className="m-0 p-0 text-white"
          style={{ fontWeight: "400", fontSize: width / 25 + "px" }}
        >
          {name || ""}
        </p>
        <div>
          {+percentage >= 0 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={width / 18}
              height={width / 18}
              fill="#90ee90"
              className="bi bi-caret-up-fill"
              viewBox="0 0 16 16"
            >
              <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={width / 18}
              height={width / 18}
              fill="red"
              className="bi bi-caret-down-fill"
              viewBox="0 0 16 16"
            >
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
          )}
          <span
            className="align-middle"
            style={{
              fontSize: width / 35 + "px",
            }}
          >
            {percentage?.toFixed(2)}%
          </span>
        </div>
        <div className="text-center">
          <p style={{ fontWeight: 680, fontSize: width / 25 + "px" }}>
            {formattedPrice} {currency}
          </p>
        </div>
      </div>
    </div>
  );
};

const Landscape = ({ cryptosData = [], currency = "" }) => {
  return (
    <div className="h-100 d-flex justify-content-center">
      {cryptosData?.map((crypto) => {
        const code = crypto?.code;
        const bgColor = crypto?.bgColor;
        const fontFamily = crypto?.fontFamily;
        const timeIntervalData = crypto?.timeIntervalData;

        const selectedCrypto = CRYPTOS.find((c) => c.code === code);
        const { name, color, logo } = selectedCrypto;

        return (
          <Crypto
            key={code}
            name={name}
            color={color}
            logo={logo}
            code={code}
            bgColor={bgColor}
            fontFamily={fontFamily}
            timeIntervalData={timeIntervalData}
            currency={currency}
          />
        );
      })}
    </div>
  );
};

export default Landscape;
