import React, { PureComponent } from "react";

export default class Text extends PureComponent {
  state = {
    date: new Date(),
    fontSize: this.props.widget.data.fontSize || 0,
    fitContent: false,
  };

  componentDidMount = () => {
    this.setBackground();
    if (this.props.widget.data.autoFontSize) {
      this.timer = setInterval(() => {
        // make font size fit the screen
        var doc = document.getElementById("doc");

        if (doc) {
          var fontSize = this.state.fontSize;
          var docHeight = doc.offsetHeight;
          var docWidth = doc.offsetWidth;
          var height = window.innerHeight;
          var width = window.innerWidth;

          if (docHeight > height || docWidth > width) {
            fontSize -= 1;
          } else if (docHeight < height && docWidth < width) {
            fontSize += 1;
          }

          this.setState({ fontSize });
        }
      }, 1);
    }
  };

  setBackground = () => {
    this.timer = setInterval(() => {
      if (this.props.widget.data.bgColor.includes("linear-gradient")) {
        document.body.style.backgroundImage = this.props.widget.data.bgColor;
      } else {
        document.body.style.backgroundColor = this.props.widget.data.bgColor;
      }
    }, 2000);
  };

  render() {
    const { widget } = this.props;
    const { fontSize } = this.state;
    if (!fontSize) this.setState({ fontSize: window.innerWidth / 4 });

    var text = widget.data.text;
    // replace newlines with <br>
    text = text.replace(/\n/g, "<br>");

    return (
      <div
        style={{
          color: widget.data.color,
          textAlign: widget.data.fontAlign,
          padding: "10px",
          overflowY: "hidden",
          overflowX: "hidden",
        }}
        id="doc"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: text,
          }}
          style={{
            textAlign: widget.data.fontAlign,
            fontWeight: widget.data.isBold ? "bolder" : "normal",
            fontStyle: widget.data.isItalic ? "italic" : "none",
            fontFamily: widget.data.fontFamily || "Arial",
            fontSize: fontSize + "px",
            lineHeight: 1,
          }}
        ></span>
      </div>
    );
  }
}
