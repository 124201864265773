export const CRYPTOS = [
  {
    name: "Bitcoin",
    color: "#f7931a",
    code: "BTC",
    logo: "bitcoin-btc-logo.svg",
  },
  {
    name: "Ethereum",
    color: "#b3ada6",
    code: "ETH",
    logo: "ethereum-eth-logo.svg",
  },
  {
    name: "Binance Coin",
    color: "#f0b90b",
    code: "BNB",
    logo: "binance-usd-busd-logo.svg",
  },
  {
    name: "Monero",
    color: "#f26822",
    code: "XMR",
    logo: "monero-xmr-logo.svg",
  },
  {
    name: "Bitcoin Cash",
    color: "#0AC18E",
    code: "BCH",
    logo: "bitcoin-cash-bch-logo.svg",
  },
  {
    name: "ZCash",
    color: "#000",
    code: "ZEC",
    logo: "zcash-zec-logo.svg",
  },
  {
    name: "Aave",
    color: "#a45ea3",
    code: "AAVE",
    logo: "aave-aave-logo.svg",
  },
  {
    name: "Kusama",
    color: "#000",
    code: "KSM",
    logo: "kusama-ksm-logo.svg",
  },
  {
    name: "Litecoin",
    color: "#345d9d",
    code: "LTC",
    logo: "litecoin-ltc-logo.svg",
  },
  {
    name: "Dash",
    color: "#008de4",
    code: "DASH",
    logo: "dash-dash-logo.svg",
  },
  {
    name: "Elrond eGold",
    color: "#000",
    code: "EGLD",
    logo: "elrond-egld-egld-logo.svg",
  },
  {
    name: "Compound",
    color: "#0AC18E",
    code: "COMP",
    logo: "compound-comp-logo.svg",
  },
  {
    name: "Solana",
    color: "#8776db",
    code: "SOL",
    logo: "solana-sol-logo.svg",
  },
  {
    name: "FTX Token",
    color: "#5fcade",
    code: "FTT",
    logo: "ftx-token-ftt-logo.svg",
  },
  {
    name: "Avalanche",
    color: "#e84142",
    code: "AVAX",
    logo: "avalanche-avax-logo.svg",
  },
  {
    name: "EhtereumClassic",
    color: "#0b8311",
    code: "ETC",
    logo: "ethereum-classic-etc-logo.svg",
  },
  {
    name: "Axie Infinity",
    color: "#0055d5",
    code: "AXS",
    logo: "axie-infinity-axs-logo.svg",
  },
  {
    name: "NEO",
    color: "#00af92",
    code: "NEO",
    logo: "neo-neo-logo.svg",
  },
  {
    name: "Livepeer",
    color: "#000",
    code: "LPT",
    logo: "livepeer-lpt-logo.svg",
  },
  {
    name: "Polkadot",
    color: "#e6007a",
    code: "DOT",
    logo: "polkadot-new-dot-logo.svg",
  },
  {
    name: "Cosmos",
    color: "#6f7390",
    code: "ATOM",
    logo: "cosmos-atom-logo.svg",
  },
];
