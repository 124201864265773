import React, { PureComponent } from "react";

import Landscape from "./components/Landscape";
import Portrait from "./components/Portrait";
import Footer from "./components/Footer";
import { getDisplayType } from "../../../utils/helper";

export default class Weather extends PureComponent {
  state = {
    date: new Date(),
    fontSize: this.props.widget?.data?.fontSize || 0,
    fitContent: false,
    displayType: "landscape",
    height: window.innerHeight,
    width: window.innerWidth,
  };

  componentDidMount = () => {
    window.addEventListener("resize", () => window.location.reload());
    const { height, width } = this.state;
    // if (height > width) {
    //   this.setState({
    //     displayType: "portrait",
    //   });
    // }
    // if (width > height) {
    //   this.setState({
    //     displayType: "landscape",
    //   });
    // }

    const displayType = getDisplayType(width, height);
    this.setState({ displayType: displayType });

    this.setState({
      fontSize: width / 8,
    });
  };

  setDisplayType(type) {
    this.setState({ displayType: type });
  }

  render() {
    const { fontSize, displayType } = this.state;
    const widget = this.props.widget;
    return (
      <div
        id="doc"
        style={{
          fontFamily: widget?.data?.fontFamily,
          color: "#fff",
          fontWeight: widget?.data?.fontBold && "900",
        }}
      >
        {displayType === "landscape" && (
          <Landscape fontSize={fontSize} widget={this.props.widget} />
        )}
        {displayType === "portrait" && (
          <Portrait
            setDisplayType={(type) => this.setDisplayType(type)}
            fontSize={fontSize}
            widget={this.props.widget}
          />
        )}
        {displayType === "footer" && (
          <Footer fontSize={fontSize} widget={this.props.widget} />
        )}
      </div>
    );
  }
}
