import React, { Fragment } from "react";

const Face3 = ({ widget, feed }) => {
  return (
    <Fragment>
      <div
        style={{
          color: widget.data.color,
          backgroundImage: widget.data.bgColor,
          fontFamily: widget.data.fontFamily,
          height: "100%",
          width: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div>
          <div
            style={{
              margin: "3px",
              padding: "10px",
              position: "absolute",
              height: window.innerHeight + "px",
              width: "100%",
              overflow: "hidden",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <div
              className="card"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                padding: "10px",
                borderRadius: "10px",
                borderBottom: "10px solid " + widget.data.color,
                width: "70%",
              }}
            >
              <span
                style={{
                  textDecoration: "none",
                  color: widget.data.color,
                }}
              >
                <span
                  className="mb-3 d-block"
                  style={{
                    fontSize: (window.innerWidth + window.innerHeight) / 50,
                    fontWeight: "bold",
                    lineHeight: 1,
                  }}
                >
                  {feed.title}
                </span>

                <span
                  className="mb-3 d-block"
                  style={{
                    fontSize: (window.innerWidth + window.innerHeight) / 50,
                    fontWeight: "bold",
                    lineHeight: 1,
                  }}
                >
                  {feed.content}
                </span>
              </span>

              {/* <div className="card-footer"></div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Face3;
