import React, { PureComponent } from "react";
import { getIndividualArea } from "../../../../utils/helper";
import { imgPath, gradientColors } from "./imgData";

class Portrait extends PureComponent {
  componentDidMount = () => {
    const big = window.innerWidth / window.innerHeight > 0.65;
    if (big && this.props.widget.aqiData.length === 1) {
      this.props.setDisplayType("landscape");
    }
    if (!big) {
      this.props.setDisplayType("footer");
    }
  };

  render() {
    const { widget } = this.props;
    const height = window.innerHeight;
    const { height: individualHeight, width: individualWidth } =
      getIndividualArea(height, window.innerWidth, widget?.aqiData?.length || 1);
    const dayOrNight =
      new Date().getHours() >= 18 || new Date().getHours() <= 6
        ? "night"
        : "day";

    const portraitOrLandscapeImgPath =
      individualHeight < window.innerWidth ? "landscape" : "portrait";

    const isForecast = !!widget?.data?.isForecast;

    return (
      <div
        style={{
          height: window.innerHeight + "px",
          width: window.innerWidth + "px",
        }}
        className=""
      >
        <div
          className="d-flex flex-column justify-content-center align-items-stretch"
          style={{
            height: window.innerHeight + "px",
            width: window.innerWidth + "px",
          }}
        >
          {widget.aqiData.map((aqi, index) => {
            const Aqi = aqi;
            const iAqi = aqi.iaqi
            const percentage = (Aqi.aqi / 500) * 100;
            const todayAqi = Aqi.aqi && Aqi.aqi[0];
            const city = Aqi.city[0];
            const forecast = aqi.forecast || [];
            const todayaqi =
            Aqi.aqi && Aqi.aqi[0];
            let description =
              todayaqi && todayaqi.main && todayaqi.main.toLowerCase();
            let tempIndex = 0;
            if (percentage <= 25) tempIndex = 0;
            if (percentage > 25 && percentage <= 60) tempIndex = 1;
            if (percentage > 60) tempIndex = 2;
            if (["Healthy"].includes(description)) description = "Healthy";
            if (["Unhealthy"].includes(description)) description = "Unhealthy";
            if (["Hazardous"].includes(description)) description = "Hazardous";
            return (
              <div
                className={`card h-100 border-0 aqi-card aqi`}
                style={{
                  borderRadius: "0px",
                  backgroundImage: gradientColors[tempIndex],
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
                key={index}
              >
                <div className="card-body py-1 h-100" style={{ zIndex: 100 }}>
                  <div
                    className={
                      "d-flex w-100 h-100 justify-content-center align-items-center"
                    }
                  >
                    {/* Temperature */}
                    <div className="d-flex flex-column h-100 justify-content-center align-items-center w-50 text-white">
                      <div
                        style={{
                          fontSize:
                            widget.aqiData.length === 1
                              ? individualWidth * 0.15 + "px"
                              : widget.aqiData.length === 2
                              ? individualWidth * 0.1 + "px"
                              : individualWidth * 0.2 + "px",
                          lineHeight: "1",
                        }}
                      >
                        {percentage <= 25
              ? "Healthy"
              : percentage > 25 && percentage <= 60
              ? "Unhealthy"
              : "Hazardous"}
                        
                      </div>
                      <div
                        style={{
                          fontSize:
                            widget.aqiData.length === 1
                              ? individualWidth * 0.15 + "px"
                              : widget.aqiData.length === 2
                              ? individualWidth * 0.35 + "px"
                              : individualWidth * 0.3 + "px",
                          fontWeight: "bold"
                        }}
                      >
                        {Aqi.aqi}
                      </div>
                      <div
                      className=""
                        style={{
                          fontSize:
                            widget.aqiData.length === 1
                              ? individualWidth * 0.1 + "px"
                              : widget.aqiData.length === 2
                              ? individualWidth * 0.10 + "px"
                              : individualWidth * 0.1 + "px",
                          fontWeight: "bold"
                        }}
                      >
                        {Aqi.city.toUpperCase() + city.slice(1)}
                      </div>
                    </div>

                    {/* Stats */}
                    {!isForecast ? (
                      <div
                        className="d-flex w-75 h-100 flex-column justify-content-center align-items-center"
                      >
                        {/* Wind & Clouds */}
                        <div
                          className="card mb-1 w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.aqiData.length === 1
                                ? individualWidth * 0.04 + "px"
                                : widget.aqiData.length === 2
                                ? individualWidth * 0.06 + "px"
                                : individualWidth * 0.09 + "px",
                          }}
                        >
                          <div className="card-body px-0 py-2">
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex text-black justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                               <span
                                style={{
                                marginRight:"12px",
                                color:"#666"}}>
                                  PM2.5
                                </span>
                                
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#555"}}>{iAqi?.pm25?.v || 0}</span>
                              </div>
                              <div className="border"></div>
                              <div
                                className="d-flex text-black justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                               <span
                                style={{
                                marginRight:"5px",
                                color:"#666"}}>
                                  PM10
                                </span>
                                
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#555"}}>{iAqi?.pm10?.v || 0}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* humidity and air pressure */}
                        <div
                          className="card mb-1 w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.aqiData.length === 1
                                ? individualWidth * 0.04 + "px"
                                : widget.aqiData.length === 2
                                ? individualWidth * 0.06 + "px"
                                : individualWidth * 0.09 + "px",
                          }}
                        >
                          <div className="card-body px-0 py-2">
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex text-black justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                                <span
                                style={{
                                marginRight:"18px",
                                color:"#666"}}>
                                  SO2
                                </span>
                                
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#555"}}>{iAqi?.so2?.v || 0}</span>
                              </div>
                              <div className="border"></div>
                              <div
                                className="d-flex text-black justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                                <span
                                style={{
                                marginRight:"18px",
                                color:"#666"}}>
                                  O3
                                </span>
                                
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#555"}}>{iAqi?.o3?.v || 0}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* sun rise and sun set */}
                        <div
                          className="card w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.aqiData.length === 1
                                ? individualWidth * 0.04 + "px"
                                : widget.aqiData.length === 2
                                ? individualWidth * 0.06 + "px"
                                : individualWidth * 0.09 + "px",
                          }}
                        >
                          <div className="card-body px-0 py-2">
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex text-black justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                               <span
                                style={{
                                marginRight:"18px",
                                color:"#666"}}>
                                  NO2
                                </span>
                                
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#555"}}>{iAqi?.no2?.v || 0}</span>
                              </div>
                              <div className="border"></div>
                              <div
                                className="d-flex text-black justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                                <span
                                style={{
                                marginRight:"18px",
                                color:"#666"}}>
                                  CO
                                </span>
                                
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#555"}}>{iAqi?.co?.v || 0}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          widget.aqiData.length === 1 ? "w-50" : "w-75"
                        }
                        style={{
                          color:
                            todayAqi.main.temp > 35 ? "#ff7e5f" : "#23282c",
                          background: "rgba(255, 255, 255, 1)",
                          borderRadius: individualWidth * 0.02 + "px",
                        }}
                      >
                        <div
                          className="card-body"
                          style={{
                            padding:
                              widget.aqiData.length === 1
                                ? individualWidth * 0.05 + "px"
                                : individualWidth * 0.02 + "px",
                          }}
                        >
                          {/* today data */}
                          <div
                            style={{
                              fontSize:
                                widget.aqiData.length === 1
                                  ? individualWidth * 0.04 + "px"
                                  : individualWidth * 0.09 + "px",
                              gap: individualWidth * 0.04 + "px",
                              fontWeight: widget.data.fontBold
                                ? "bolder"
                                : "normal",
                            }}
                          >
                            <div className="d-flex">
                              <div
                                className="d-flex justify-content-center w-100"
                                style={{ marginLeft: "auto" }}
                              >
                                <span>
                                  {description === "clouds" ? (
                                    <i className="bi bi-cloud"></i>
                                  ) : description === "clear" ? (
                                    <i className="bi bi-brightness-high"></i>
                                  ) : description === "rain" ? (
                                    <i className="bi bi-cloud-drizzle"></i>
                                  ) : description === "haze" ? (
                                    <i className="bi bi-cloud-haze2"></i>
                                  ) : description === "snow" ? (
                                    <i className="bi bi-cloud-snow"></i>
                                  ) : description === "thunderstorm" ? (
                                    <i className="bi bi-thunderstorm"></i>
                                  ) : (
                                    <i className="bi bi-brightness-high"></i>
                                  )}
                                </span>

                                <span
                                  className="text-capitalize"
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {description.toLowerCase()}
                                </span>
                              </div>
                              <div className="d-flex justify-content-center w-100">
                                <span className="">
                                  <i className="bi bi-wind"></i>
                                </span>
                                <span
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {todayAqi.wind.speed}
                                </span>
                              </div>
                            </div>

                            <div className="d-flex">
                              <div className="d-flex justify-content-center w-100">
                                <span className="">
                                  <i className="bi bi-sunrise"></i>
                                </span>
                                <span
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {city.sunrise || ""}
                                </span>
                              </div>
                              <div className="d-flex justify-content-center w-100">
                                <span className="">
                                  <i className="bi bi-sunset"></i>
                                </span>
                                <span
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {city.sunset || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* Forecast */}
                          <div
                            className="d-flex justify-content-around"
                            style={{ marginTop: individualWidth * 0.02 + "px" }}
                          >
                            {forecast.map((data, index) => {
                              const description =
                                data.aqi &&
                                data.aqi[0].main &&
                                data.aqi[0].main.toLowerCase();
                              const day = new Date(
                                data.dt * 1000
                              ).toLocaleDateString("en-US", {
                                weekday: "short",
                              });
                              const temperature = data.main.temp;
                              return (
                                <div
                                  className="d-flex flex-column justify-content-center align-items-center"
                                  key={index}
                                  style={{
                                    background: "#b6bbc7",
                                    borderRadius: individualWidth * 0.02 + "px",
                                    padding: individualWidth * 0.01 + "px",
                                    width: "30%",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize:
                                        widget.aqiData.length === 1
                                          ? individualWidth * 0.03 + "px"
                                          : individualWidth * 0.1 + "px",
                                    }}
                                  >
                                    {description === "clouds" ? (
                                      <i className="bi bi-cloud"></i>
                                    ) : description === "clear" ? (
                                      <i className="bi bi-brightness-high"></i>
                                    ) : description === "rain" ? (
                                      <i className="bi bi-cloud-drizzle"></i>
                                    ) : description === "haze" ? (
                                      <i className="bi bi-cloud-haze2"></i>
                                    ) : description === "snow" ? (
                                      <i className="bi bi-cloud-snow"></i>
                                    ) : description === "thunderstorm" ? (
                                      <i className="bi bi-thunderstorm"></i>
                                    ) : (
                                      <i className="bi bi-brightness-high"></i>
                                    )}
                                  </span>
                                  <span
                                    style={{
                                      fontSize:
                                        widget.aqiData.length === 1
                                          ? individualWidth * 0.03 + "px"
                                          : individualWidth * 0.08 + "px",
                                    }}
                                  >
                                    {day}
                                  </span>
                                  <span
                                    style={{
                                      fontSize:
                                        widget.aqiData.length === 1
                                          ? individualWidth * 0.03 + "px"
                                          : widget.aqiData.length === 2
                                          ? individualWidth * 0.08 + "px"
                                          : individualWidth * 0.1 + "px",
                                      lineHeight: 1.8,
                                    }}
                                  >
                                    {temperature.toFixed()} °C
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Portrait;
