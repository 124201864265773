import React, { PureComponent } from "react";
import { getIndividualArea } from "../../../../utils/helper";
import { imgPath, gradientColors } from "./imgData";

class Landscape extends PureComponent {
  state = {
    fontSize: window.innerWidth / 24,
  };

  componentDidMount() {}

  render() {
    const { widget } = this.props;
    const height = window.innerHeight;
    const { height: individualHeight, width: individualWidth } =
      getIndividualArea(
        window.innerHeight,
        window.innerWidth,
        widget.weather.length
      );

    const isForecast = !!widget?.data?.isForecast;

    const dayOrNight =
      new Date().getHours() >= 18 || new Date().getHours() <= 6
        ? "night"
        : "day";

    const portraitOrLandscapeImgPath =
      individualWidth < window.innerHeight ? "portrait" : "landscape";
    return (
      <div
        style={{
          width: window.innerWidth + "px",
        }}
      >
        <div
          className="d-flex justify-content-center align-items-center m-0 p-0 h-100"
          style={{
            width: window.innerWidth + "px",
          }}
        >
          {widget.weather.map((weather, index) => {
            const todayData = weather.todayData;
            const city = weather.city;
            const forecast = weather.forecast;
            const todayWeather =
              todayData && todayData.weather && todayData.weather[0];
            let description =
              todayWeather &&
              todayWeather.main &&
              todayWeather.main.toLowerCase();
            const temp = todayData && todayData.main && todayData.main.temp;
            let tempIndex = 0;
            if (temp > 5 && temp <= 10) tempIndex = 1;
            else if (temp > 10 && temp <= 15) tempIndex = 2;
            else if (temp > 15 && temp <= 18) tempIndex = 3;
            else if (temp > 18 && temp <= 22) tempIndex = 4;
            else if (temp > 22 && temp <= 25) tempIndex = 5;
            else if (temp > 25 && temp <= 30) tempIndex = 6;
            else if (temp > 30 && temp <= 35) tempIndex = 7;
            else if (temp > 35) tempIndex = 8;
            if (["thunderstorm", "drizzle", "rain"].includes(description))
              description = "rain";
            else if (
              [
                "mist",
                "smoke",
                "haze",
                "dust",
                "fog",
                "sand",
                "dust",
                "ash",
                "squall",
                "tornado",
              ].includes(description)
            )
              description = "clear";
            return (
              <div
                className={`card w-100 h-100 border-0 p-0 m-0 position-relative weather-card weather ${
                  widget.data.animation && description
                }`}
                style={{
                  borderRadius: "0px",
                  fontFamily: widget.data.fontFamily,
                  backgroundImage: widget.data.bgImage
                    ? `linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.498)), url(${imgPath[portraitOrLandscapeImgPath][description][dayOrNight]})`
                    : // : gradientColors[todayData.main.temp < 35 ? 1 : 2],
                      gradientColors[tempIndex],
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  display: "inline-block",
                  // height: height + "px",
                }}
                key={index}
              >
                <div
                  className="card-body p-0 position-relative"
                  style={{ zIndex: 100 }}
                >
                  <div
                    className={`d-flex flex-column w-100 ${"justify-content-center"} align-items-center`}
                    style={{
                      height: window.innerHeight + "px",
                    }}
                  >
                    <div
                      style={{
                        marginBottom:
                          widget.weather.length === 1
                            ? height * 0.1 + "px"
                            : widget.weather.length === 2
                            ? height * 0.1 + "px"
                            : height * 0.1 + "px",
                      }}
                      className="me-2 text-center"
                    >
                      <div
                        style={{
                          fontSize:
                            widget.weather.length === 1
                              ? individualHeight * 0.07 + "px"
                              : widget.weather.length === 2
                              ? individualWidth * 0.1 + "px"
                              : individualWidth * 0.15 + "px",
                        }}
                      >
                        {description === "clouds" ? (
                          <i className="bi bi-cloud"></i>
                        ) : description === "clear" ? (
                          <i className="bi bi-brightness-high"></i>
                        ) : description === "rain" ? (
                          <i className="bi bi-cloud-drizzle"></i>
                        ) : description === "haze" ? (
                          <i className="bi bi-cloud-haze2"></i>
                        ) : description === "snow" ? (
                          <i className="bi bi-cloud-snow"></i>
                        ) : description === "thunderstorm" ? (
                          <i className="bi bi-thunderstorm"></i>
                        ) : (
                          <i className="bi bi-brightness-high"></i>
                        )}
                      </div>
                      <div
                        style={{
                          fontSize:
                            widget.weather.length === 1
                              ? individualHeight > individualWidth
                                ? individualWidth * 0.15 + "px"
                                : individualHeight * 0.12 + "px"
                              : widget.weather.length === 2
                              ? individualHeight * 0.2 + "px"
                              : individualWidth * 0.25 + "px",
                        }}
                      >
                        {todayData?.main?.temp?.toFixed(0)}
                        °C
                      </div>
                      <span
                        style={{
                          fontSize:
                            widget.weather.length === 1
                              ? individualHeight > individualWidth
                                ? individualWidth * 0.08 + "px"
                                : individualHeight * 0.06 + "px"
                              : widget.weather.length === 2
                              ? individualWidth * 0.12 + "px"
                              : individualWidth * 0.15 + "px",
                          marginBottom: individualWidth * 0.01 + "px",
                        }}
                        className="d-block"
                      >
                        {city.name}
                      </span>
                    </div>

                    {!isForecast ? (
                      <div
                        style={{
                          color:
                            todayData.main.temp > 35 ? "#ff7e5f" : "#3a6073",
                        }}
                      >
                        {/* Environment & Wind */}
                        <div
                          className="card mb-1 w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.weather.length === 1
                                ? individualHeight > individualWidth
                                  ? individualWidth * 0.04 + "px"
                                  : individualHeight * 0.045 + "px"
                                : widget.weather.length === 2
                                ? individualWidth * 0.05 + "px"
                                : individualWidth * 0.05 + "px",
                          }}
                        >
                          <div
                            className="card-body px-0"
                            style={{
                              paddingTop:
                                widget.weather.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.weather.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              paddingBottom:
                                widget.weather.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.weather.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              width: individualWidth / 1.5 + "px",
                            }}
                          >
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33.3%" }}
                              >
                                <span>
                                  {description === "clouds" ? (
                                    <i className="bi bi-cloud"></i>
                                  ) : description === "clear" ? (
                                    <i className="bi bi-brightness-high"></i>
                                  ) : description === "rain" ? (
                                    <i className="bi bi-cloud-drizzle"></i>
                                  ) : description === "haze" ? (
                                    <i className="bi bi-cloud-haze2"></i>
                                  ) : description === "snow" ? (
                                    <i className="bi bi-cloud-snow"></i>
                                  ) : description === "thunderstorm" ? (
                                    <i className="bi bi-thunderstorm"></i>
                                  ) : (
                                    <i className="bi bi-brightness-high"></i>
                                  )}
                                </span>

                                <span>{description.toUpperCase()}</span>
                              </div>
                              <div className="border"></div>
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33.3%" }}
                              >
                                <span className="">
                                  <i className="bi bi-wind"></i>
                                </span>
                                <span>{todayData.wind.speed}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* humidity and air pressure */}
                        <div
                          className="card mb-1 w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.weather.length === 1
                                ? individualHeight > individualWidth
                                  ? individualWidth * 0.04 + "px"
                                  : individualHeight * 0.045 + "px"
                                : widget.weather.length === 2
                                ? individualWidth * 0.05 + "px"
                                : individualWidth * 0.05 + "px",
                          }}
                        >
                          <div
                            className="card-body px-0"
                            style={{
                              paddingTop:
                                widget.weather.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.weather.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              paddingBottom:
                                widget.weather.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.weather.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              width: individualWidth / 1.5 + "px",
                            }}
                          >
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33.3%" }}
                              >
                                <span className="mx-1">
                                  <i className="bi bi-droplet"></i>
                                </span>
                                <span className="">
                                  {todayData.main.humidity}
                                </span>{" "}
                              </div>
                              <div className="border"></div>
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33.3%" }}
                              >
                                <span className="">
                                  <i className="bi bi-speedometer2"></i>
                                </span>
                                <span>{todayData.main.pressure}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* sun rise and sun set */}
                        <div
                          className="card w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.weather.length === 1
                                ? individualHeight > individualWidth
                                  ? individualWidth * 0.04 + "px"
                                  : individualHeight * 0.045 + "px"
                                : widget.weather.length === 2
                                ? individualWidth * 0.05 + "px"
                                : individualWidth * 0.05 + "px",
                          }}
                        >
                          <div
                            className="card-body px-0"
                            style={{
                              paddingTop:
                                widget.weather.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.weather.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              paddingBottom:
                                widget.weather.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.weather.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              width: individualWidth / 1.5 + "px",
                            }}
                          >
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33.3%" }}
                              >
                                <span className="">
                                  <i className="bi bi-sunrise"></i>
                                </span>
                                <span>{city.sunrise || ""}</span>
                              </div>
                              <div className="border"></div>
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33.3%" }}
                              >
                                <span className="">
                                  <i className="bi bi-sunset"></i>
                                </span>
                                <span>{city.sunset || ""}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          widget.weather.length === 1
                            ? individualHeight > individualWidth
                              ? "w-75"
                              : "w-50"
                            : "w-75"
                        }
                        style={{
                          color:
                            todayData.main.temp > 35 ? "#ff7e5f" : "#23282c",
                          background: "rgba(255, 255, 255, 1)",
                          borderRadius: individualWidth * 0.02 + "px",
                        }}
                      >
                        <div
                          className="card-body"
                          style={{
                            padding:
                              widget.weather.length === 1
                                ? individualHeight * 0.02 + "px"
                                : widget.weather.length === 2
                                ? individualHeight * 0.02 + "px"
                                : individualHeight * 0.02 + "px",
                          }}
                        >
                          {/* today data */}
                          <div
                            style={{
                              fontSize:
                                widget.weather.length === 1
                                  ? individualHeight > individualWidth
                                    ? individualWidth * 0.05 + "px"
                                    : individualHeight * 0.04 + "px"
                                  : widget.weather.length === 2
                                  ? individualHeight * 0.07 + "px"
                                  : individualWidth * 0.07 + "px",
                              // gap: individualWidth * 0.02 + "px",
                              marginBottom: individualHeight * 0.03 + "px",
                            }}
                          >
                            <div className="d-flex justify-content-between">
                              <div
                                className="d-flex justify-content-center w-75"
                                style={{ marginLeft: "auto" }}
                              >
                                <span>
                                  {description === "clouds" ? (
                                    <i className="bi bi-cloud"></i>
                                  ) : description === "clear" ? (
                                    <i className="bi bi-brightness-high"></i>
                                  ) : description === "rain" ? (
                                    <i className="bi bi-cloud-drizzle"></i>
                                  ) : description === "haze" ? (
                                    <i className="bi bi-cloud-haze2"></i>
                                  ) : description === "snow" ? (
                                    <i className="bi bi-cloud-snow"></i>
                                  ) : description === "thunderstorm" ? (
                                    <i className="bi bi-thunderstorm"></i>
                                  ) : (
                                    <i className="bi bi-brightness-high"></i>
                                  )}
                                </span>

                                <span
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                  className="text-capitalize"
                                >
                                  {description.toLowerCase()}
                                </span>
                              </div>
                              <div
                                className="d-flex justify-content-center w-75"
                                // style={{ justifySelf: "end" }}
                              >
                                <span className="">
                                  <i className="bi bi-wind"></i>
                                </span>
                                <span
                                  style={{
                                    // marginRight: "auto",
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {todayData.wind.speed}
                                </span>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between">
                              <div
                                className="d-flex justify-content-center w-75"
                                // style={{ justifySelf: "end" }}
                              >
                                <span className="">
                                  <i className="bi bi-sunrise"></i>
                                </span>
                                <span
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {city.sunrise || ""}
                                </span>
                              </div>
                              <div
                                className="d-flex justify-content-center w-75"
                                // style={{ justifySelf: "end" }}
                              >
                                <span className="">
                                  <i className="bi bi-sunset"></i>
                                </span>
                                <span
                                  style={{
                                    // marginRight: "auto",
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {city.sunrise || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* Forecast */}
                          <div
                            className="d-flex justify-content-around"
                            style={{
                              margin:
                                widget.weather.length === 1 &&
                                individualWidth * 0.01 + "px 0",
                            }}
                          >
                            {forecast.map((data, index) => {
                              const description =
                                data.weather &&
                                data.weather[0].main &&
                                data.weather[0].main.toLowerCase();
                              const day = new Date(
                                data.dt * 1000
                              ).toLocaleDateString("en-US", {
                                weekday: "short",
                              });
                              const temperature = data.main.temp;
                              return (
                                <div
                                  className="d-flex flex-column justify-content-center align-items-center"
                                  key={index}
                                  style={{
                                    background: "#b6bbc7",
                                    borderRadius: individualWidth * 0.02 + "px",
                                    padding:
                                      widget.weather.length === 1
                                        ? individualWidth * 0.001 + "px"
                                        : individualWidth * 0.01 + "px",
                                    width: "30%",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize:
                                        widget.weather.length === 1
                                          ? individualHeight * 0.025 + "px"
                                          : individualWidth * 0.05 + "px",
                                    }}
                                  >
                                    {description === "clouds" ? (
                                      <i className="bi bi-cloud"></i>
                                    ) : description === "clear" ? (
                                      <i className="bi bi-brightness-high"></i>
                                    ) : description === "rain" ? (
                                      <i className="bi bi-cloud-drizzle"></i>
                                    ) : description === "haze" ? (
                                      <i className="bi bi-cloud-haze2"></i>
                                    ) : description === "snow" ? (
                                      <i className="bi bi-cloud-snow"></i>
                                    ) : description === "thunderstorm" ? (
                                      <i className="bi bi-thunderstorm"></i>
                                    ) : (
                                      <i className="bi bi-brightness-high"></i>
                                    )}
                                  </span>
                                  <span
                                    style={{
                                      fontSize:
                                        widget.weather.length === 1
                                          ? individualHeight > individualWidth
                                            ? individualWidth * 0.04 + "px"
                                            : individualHeight * 0.025 + "px"
                                          : widget.weather.length === 2
                                          ? individualWidth * 0.03 + "px"
                                          : individualWidth * 0.04 + "px",
                                    }}
                                  >
                                    {day}
                                  </span>
                                  <strong
                                    style={{
                                      fontSize:
                                        widget.weather.length === 1
                                          ? individualHeight > individualWidth
                                            ? individualWidth * 0.04 + "px"
                                            : individualHeight * 0.03 + "px"
                                          : widget.weather.length === 2
                                          ? individualWidth * 0.04 + "px"
                                          : individualWidth * 0.05 + "px",
                                    }}
                                  >
                                    {temperature.toFixed()} °C
                                  </strong>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Landscape;
