import React, { Fragment, PureComponent } from "react";
import Clock from "react-live-clock";
import { ANALOG_CLOCKS } from "..";
import { getIndividualArea } from "../../../../utils/helper";

class Footer extends PureComponent {
  state = {
    date: "",
    tz: this.props.widget?.data?.tz,
  };

  componentDidMount = () => {
    this.startInterval();
    // set body background color
    if (this.props.widget.data.bgColor.includes("linear-gradient")) {
      document.body.style.background = this.props.widget.data.bgColor;
    } else document.body.style.backgroundColor = this.props.widget.data.bgColor;
    if (this.props.widget.data.faceId === "1") {
      function scrollbarVisible(element) {
        return element.scrollHeight > element.clientHeight;
      }
      const doc = document.querySelector("#doc");
      const hasScrollbar = scrollbarVisible(doc);

      this.setState({ hasScrollbar });
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  startInterval = () => {
    this.timer && clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.state.tz.forEach((timezone, index) => {
        var d = new Date();

        var utc = d.getTime() + d.getTimezoneOffset() * 60000;
        d = new Date(utc + 3600000 * timezone.offset);

        const hr = d.getHours();
        const min = d.getMinutes();
        const sec = d.getSeconds();
        const hr_rotation = 30 * hr + min / 2; //converting current time
        const min_rotation = 6 * min;
        const sec_rotation = 6 * sec;

        try {
          document.getElementById(
            "hour" + index + 1
          ).style.transform = `rotate(${hr_rotation}deg)`;
          document.getElementById(
            "minute" + index + 1
          ).style.transform = `rotate(${min_rotation}deg)`;
          document.getElementById(
            "second" + index + 1
          ).style.transform = `rotate(${sec_rotation}deg)`;
        } catch (err) {}
      });
      this.setState({
        date: new Date().toString(),
      });
    }, 1000);
  };

  render() {
    const { fontSize, widget } = this.props;
    const { height, width } = getIndividualArea(
      this.props.height,
      this.props.width,
      this.props.widget.data.tz.length
    );

    let cityNames = widget.data.cityNames || [];
    if (
      "cityName0" in widget.data ||
      "cityName1" in widget.data ||
      "cityName2" in widget.data
    ) {
      cityNames = [];
      for (let i = 0; i < 3; i++) {
        if (`cityName${i}` in widget.data) {
          cityNames.push(widget.data[`cityName${i}`]);
        }
      }
    }

    return (
      <Fragment>
        {/* analog */}
        {widget.data.faceId === "1" && (
          <div
            className="d-flex w-100 g-1 justify-content-around align-items-center"
            style={{
              height: "100vh",
            }}
          >
            {widget?.data?.tz?.map((timezone, index) => {
              var d = new Date();

              var utc = d.getTime() + d.getTimezoneOffset() * 60000;
              d = new Date(utc + 3600000 * timezone.offset).toLocaleDateString(
                "en-in",
                {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }
              );
              return (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div
                    className="clockContainer2 m-0"
                    style={{
                      background: `url(${
                        ANALOG_CLOCKS[
                          widget.data?.selectedAnalog?.analogId || 0
                        ]
                      }) no-repeat`,
                      backgroundSize: "100%",
                      height:
                        widget.data.tz.length === 1
                          ? height * 0.8 + "px"
                          : widget.data.tz.length === 2
                          ? height * 1.5 + "px"
                          : widget.data.tz.length === 3
                          ? height * 2.1 + "px"
                          : widget.data.tz.length === 4
                          ? height * 1.8 + "px"
                          : height * 3.2 + "px",
                      width:
                        widget.data.tz.length === 1
                          ? height * 0.8 + "px"
                          : widget.data.tz.length === 2
                          ? height * 1.5 + "px"
                          : widget.data.tz.length === 3
                          ? height * 2.1 + "px"
                          : widget.data.tz.length === 4
                          ? height * 1.8 + "px"
                          : height * 3.2 + "px",
                    }}
                  >
                    <div
                      className="hour"
                      id={"hour" + index + 1}
                      style={{
                        background:
                          widget.data?.selectedAnalog?.dialColor || "#000",
                      }}
                    ></div>
                    <div
                      className="minute"
                      id={"minute" + index + 1}
                      style={{
                        background:
                          widget.data?.selectedAnalog?.dialColor || "#000",
                      }}
                    ></div>
                    <div
                      className="second"
                      id={"second" + index + 1}
                      style={{
                        background:
                          widget.data?.selectedAnalog?.dialColor || "#000",
                      }}
                    ></div>
                  </div>
                  <div
                    className="text-center text-capitalize"
                    style={{
                      fontSize:
                        widget.data.tz.length === 1
                          ? height * 0.12 + "px"
                          : widget.data.tz.length === 2
                          ? height * 0.3 + "px"
                          : height * 0.45 + "px",
                      color: widget.data.color,
                      fontFamily: widget.data.fontFamily,
                    }}
                  >
                    {new Date().getSeconds() % 2 === 0 && widget.data.showDate
                      ? d
                      : cityNames[index]}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* digital */}
        {widget.data.faceId === "2" && (
          <div
            className="row g-0 w-100 justify-content-stretch align-items-stretch m-0"
            style={{
              height: "100vh",
            }}
          >
            {widget?.data?.tz?.map((timezone, index) => {
              var d = new Date();

              var utc = d.getTime() + d.getTimezoneOffset() * 60000;
              d = new Date(utc + 3600000 * timezone.offset);
              const twentyFourHour = !!widget?.data?.twentyFourHourFormat;
              const formattedTime = d.toLocaleTimeString([], {
                hour: "2-digit",
                hourCycle: twentyFourHour ? "h24" : "h12",
                minute: "2-digit",
              });
              const formattedDate = d.toLocaleDateString("en-in", {
                day: "numeric",
                month: "short",
                year: "numeric",
              });
              const height = window.innerHeight;
              return (
                <div className="col text-center h-100" key={index}>
                  <div
                    className="card d-flex justify-content-center align-items-center h-100"
                    key={this.state.date + index}
                    style={{
                      background: widget.data.bgColor,
                      color: widget.data.color,
                      fontFamily: widget.data.fontFamily,
                      border: "none",
                    }}
                  >
                    <div
                      className="text-uppercase"
                      style={{
                        fontFamily: widget.data.fontFamily,
                        fontSize: !twentyFourHour
                          ? height * 0.18 + "px"
                          : height * 0.2 + "px",
                        lineHeight: !twentyFourHour
                          ? height * 0.2 + "px"
                          : height * 0.2 + "px",
                      }}
                    >
                      {formattedTime}
                    </div>

                    <div
                      className="text-center text-capitalize"
                      style={{
                        fontFamily: widget.data.fontFamily,
                        fontSize: !twentyFourHour
                          ? height * 0.1 + "px"
                          : height * 0.12 + "px",
                        wordBreak: "break-all",
                      }}
                    >
                      {widget.data?.showDate && (
                        <p
                          style={{
                            fontSize: !twentyFourHour
                              ? height * 0.08 + "px"
                              : height * 0.1 + "px",
                          }}
                          className="m-0 p-0"
                        >
                          {formattedDate}
                        </p>
                      )}
                      <p className="m-0 p-0">{cityNames[index]}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Fragment>
    );
  }
}

export default Footer;
