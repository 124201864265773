import React, { useEffect, useState } from "react";
import Portrait from "./components/Portrait";
import Landscape from "./components/Landscape";
import { getDisplayType } from "../../../utils/helper";

const Wifi = ({ widget }) => {
  const [displayType, setDisplayType] = useState("landscape");
  const height = window.innerHeight;
  const width = window.innerWidth;

  useEffect(() => {
    const displayType = getDisplayType(width, height);
    setDisplayType(displayType);
  }, [height, width]);

  return (
    <>
      {displayType === "landscape" && <Landscape widget={widget} />}
      {displayType === "portrait" && <Portrait widget={widget} />}
    </>
  );
};

export default Wifi;
