export const getInformalDateTime = (d) => {
  const someDate = new Date(d);
  const todayDate = new Date();

  const someDay = someDate.getDate();
  const someMonth = someDate.getMonth() + 1;
  const someYear = someDate.getFullYear();
  const someHour = someDate.getHours();
  const someMinutes = someDate.getMinutes();
  const someSec = someDate.getSeconds();

  const today = todayDate.getDate();
  const month = todayDate.getMonth() + 1;
  const year = todayDate.getFullYear();
  const hr = todayDate.getHours();
  const min = todayDate.getMinutes();
  const sec = todayDate.getSeconds();

  if (Math.abs(year - someYear) > 0) return Math.abs(year - someYear) + "y ago";
  if (Math.abs(month - someMonth) > 0)
    return Math.abs(month - someMonth) + " month ago";
  if (Math.abs(today - someDay) > 0) return Math.abs(today - someDay) + "d ago";
  if (Math.abs(hr - someHour) > 0) return Math.abs(hr - someHour) + "h ago";
  if (Math.abs(min - someMinutes) > 0)
    return Math.abs(min - someMinutes) + "min ago";
  if (Math.abs(sec - someSec > 0)) return Math.abs(sec - someSec) + "s ago";
  else return "now";
};

export const getIndianNumber = (num) => {
  return num.toLocaleString("en-IN", {
    currency: "INR",
  });
};

export const getDisplayType = (width, height) => {
  const ratio = width / height;
  if (ratio < 1) return "portrait";
  if (ratio > 2.5) return "footer";
  return "landscape";
};

export const getRatio = (numOfElements) => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  const displayType = getDisplayType(width, height);
  const ratio = displayType === "portrait" ? height / width : width / height;

  if (numOfElements === 1) {
    return ratio;
  }
  if (numOfElements === 2) {
    return ratio * 1.5;
  }
  if (numOfElements === 3) {
    return ratio * 2.5;
  }
};

export const getIndividualArea = (height, width, numOfElements) => {
  const newHeight = height / numOfElements;
  const newWidth = width / numOfElements;
  return { height: newHeight, width: newWidth };
};
