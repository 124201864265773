import React, { PureComponent } from "react";
import { getIndividualArea } from "../../../../utils/helper";
import { imgPath, gradientColors } from "./imgData";

class Footer extends PureComponent {
  componentDidMount = () => {};

  render() {
    const { widget } = this.props;
    const height = window.innerHeight;
    const width = window.innerWidth;

    const isLandscape = window.innerHeight < window.innerWidth;
    const { height: individualHeight, width: individualWidth } =
      getIndividualArea(height, width, widget.data.city.length);

    const dayOrNight =
      new Date().getHours() >= 18 || new Date().getHours() <= 6
        ? "night"
        : "day";

    const portraitOrLandscapeImgPath = isLandscape ? "landscape" : "portrait";

    return (
      <div
        style={{
          height: window.innerHeight + "px",
          width: window.innerWidth + "px",
        }}
        className="p-0 m-0"
      >
        <div
          className={
            "d-flex justify-content-center align-items-stretch m-0 p-0 " +
            (window.innerHeight > window.innerWidth
              ? "flex-column"
              : "flex-row")
          }
          style={{
            width: window.innerWidth + "px",
            height: window.innerHeight + "px",
          }}
        >
          {widget.weather.map((weather, index) => {
            const todayData = weather.todayData;
            const todayWeather = todayData.weather[0];
            let description =
              todayWeather.main && todayWeather.main.toLowerCase();

            const temp = todayData && todayData.main && todayData.main.temp;
            let tempIndex = 0;
            if (temp > 5 && temp <= 10) tempIndex = 1;
            else if (temp > 10 && temp <= 15) tempIndex = 2;
            else if (temp > 15 && temp <= 18) tempIndex = 3;
            else if (temp > 18 && temp <= 22) tempIndex = 4;
            else if (temp > 22 && temp <= 25) tempIndex = 5;
            else if (temp > 25 && temp <= 30) tempIndex = 6;
            else if (temp > 30 && temp <= 35) tempIndex = 7;
            else if (temp > 35) tempIndex = 8;

            if (["thunderstorm", "drizzle", "rain"].includes(description))
              description = "rain";
            else if (
              [
                "mist",
                "smoke",
                "haze",
                "dust",
                "fog",
                "sand",
                "dust",
                "ash",
                "squall",
                "tornado",
              ].includes(description)
            )
              description = "clear";
            return (
              <div
                className={`card p-0 m-0 border-0 weather-card weather ${
                  widget.data.animation && description
                }`}
                style={{
                  // width:
                  //   window.innerHeight > window.innerWidth
                  //     ? window.innerWidth + "px"
                  //     : window.innerWidth / widget.data.city.length + "px",
                  //     height: window.innerHeight + "px",
                  width:
                    window.innerWidth > window.innerHeight
                      ? individualWidth
                      : window.innerWidth,
                  height:
                    window.innerWidth > window.innerHeight
                      ? window.innerHeight
                      : individualHeight,
                  borderRadius: "0px",

                  backgroundImage: widget.data.bgImage
                    ? `linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.498)), url(${imgPath[portraitOrLandscapeImgPath][description][dayOrNight]})`
                    : gradientColors[tempIndex],
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
                key={index}
              >
                <div className="card-body p-0 h-100" style={{ zIndex: 100 }}>
                  <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                    <div className="w-100">
                      <div
                        style={{
                          fontSize: isLandscape
                            ? widget.weather.length === 1
                              ? individualHeight * 0.3 + "px"
                              : widget.weather.length === 2
                              ? individualHeight * 0.4 + "px"
                              : individualHeight * 1 + "px"
                            : widget.weather.length === 1
                            ? individualWidth * 0.3 + "px"
                            : widget.weather.length === 2
                            ? individualWidth * 0.45 + "px"
                            : individualWidth * 0.65 + "px",
                          lineHeight: "1",
                        }}
                        className="text-center"
                      >
                        <span className="">
                          {todayData.main.temp.toFixed(0)}
                          °C
                        </span>
                      </div>
                      <div
                        className="text-center"
                        style={{
                          fontSize: isLandscape
                            ? widget.weather.length === 1
                              ? individualHeight * 0.16 + "px"
                              : widget.weather.length === 2
                              ? individualHeight * 0.2 + "px"
                              : individualHeight * 0.4 + "px"
                            : widget.weather.length === 1
                            ? individualWidth * 0.2 + "px"
                            : widget.weather.length === 2
                            ? individualWidth * 0.25 + "px"
                            : individualWidth * 0.27 + "px",
                        }}
                      >
                        {description === "clouds" ? (
                          <span>
                            <i className="bi bi-cloud"></i> Clouds
                          </span>
                        ) : description === "clear" ? (
                          <span>
                            <i className="bi bi-brightness-high"></i> Clear
                          </span>
                        ) : description === "rain" ? (
                          <span>
                            <i className="bi bi-cloud-drizzle"></i> Rain
                          </span>
                        ) : description === "haze" ? (
                          <span>
                            <i className="bi bi-cloud-haze2"></i> Haze
                          </span>
                        ) : description === "snow" ? (
                          <span>
                            <i className="bi bi-cloud-snow"></i>
                            Snow
                          </span>
                        ) : description === "thunderstorm" ? (
                          <span>
                            <i className="bi bi-thunderstorm"></i>
                            Thunderstorm
                          </span>
                        ) : (
                          <span>
                            <i className="bi bi-brightness-high"></i>
                            Clear
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="text-center">
                      <span
                        className="d-block"
                        style={{
                          fontSize: isLandscape
                            ? widget.weather.length === 1
                              ? individualHeight * 0.25 + "px"
                              : widget.weather.length === 2
                              ? individualHeight * 0.3 + "px"
                              : individualHeight * 0.55 + "px"
                            : widget.weather.length === 1
                            ? individualWidth * 0.2 + "px"
                            : widget.weather.length === 2
                            ? individualWidth * 0.35 + "px"
                            : individualWidth * 0.37 + "px",
                        }}
                      >
                        {weather.city.name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Footer;
