import { useState, useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";

const Index = ({ widget }) => {
  const [displayType, setDisplayType] = useState("");
  const height = window.innerHeight;
  const width = window.innerWidth;

  useEffect(() => {
    if (height > width) setDisplayType("portrait");
    if (width > height && width < height * 2) setDisplayType("landscape");
    if (width >= 4.5 * height) setDisplayType("footer");

    if (widget.data.bgColor === "transparent") {
      document.body.style.backgroundColor = "transparent";
    }
  }, [height, width, setDisplayType]);

  let size = width / 3;
  let fontSize = width / 30;
  if (displayType === "footer") {
    size = height / 2;
    fontSize = height / 10;
  }

  const { data } = widget;
  const {
    qrText,
    qrColor,
    titleText,
    titleColor,
    fontFamily,
    bgColor,
    fileUrl,
  } = data;

  return (
    <div
      className="h-100 d-flex flex-column align-items-center justify-content-center "
      style={{ background: bgColor }}
    >
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ width: width }}
      >
        <QRCodeSVG
          bgColor="transparent"
          fgColor={qrColor}
          level="M"
          size={size}
          value={qrText}
          imageSettings={
            !!fileUrl && {
              src: fileUrl,
              height: size / 4,
              width: size / 4,
              excavate: true,
            }
          }
        />
      </div>
      <p
        className="mt-4"
        style={{
          color: titleColor,
          fontFamily: fontFamily,
          fontSize: fontSize,
        }}
      >
        {titleText}
      </p>
    </div>
  );
};

export default Index;
