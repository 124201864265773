import React, { useState, useEffect } from "react";

const IndiaToday = ({ widget }) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const faceId = widget.faceId;
  const fontFamily = widget.data?.fontFamily;
  const bgColor = widget.data?.bgColor;
  const color = widget.data?.color;
  const logoUrl = widget.data?.logoUrl || "/images/indiatoday.avg";
  const rssFeed = widget.data?.feeds;

  const [feed, setFeed] = useState({});

  useEffect(() => {
    if (rssFeed.length > 0) {
      setFeed({
        title: rssFeed[0]?.title,
        description: rssFeed[0]?.content,
      });
    }
    var i = 0;
    setInterval(() => {
      if (i >= rssFeed.length - 1) {
        i = 0;

        setFeed({
          title: rssFeed[0]?.title,
          description: rssFeed[0]?.content,
        });
      } else {
        setFeed({
          title: rssFeed[i + 1]?.title,
          description: rssFeed[i + 1]?.content,
        });
        i++;
      }
    }, 10000);
  }, []);

  return window.innerWidth / window.innerHeight > 2.5 ? (
    <div
      style={{ width: width + "px", height: height + "px", color, fontFamily }}
      className="overflow-hidden d-flex justify-content-center align-items-center"
    >
      <div
        style={{
          color: widget.data.color,
          backgroundImage: widget.data.bgColor,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "center",
          fontFamily: widget.data.fontFamily,
          height: "100vh",
          width: "100%",
        }}
        className="row align-items-center"
      >
        <div
          className="d-flex justify-content-center align-items-center col-3"
          style={{
            background: "#e4dede",
            height: "100vh",
            width: "15vw",
            borderTopRightRadius: "20%",
            borderBottomRightRadius: "20%",
          }}
        >
          <img
            src="/images/indiatoday.svg"
            alt="logo"
            style={{
              height: "90vh",
              width: "10vw",
              padding: "5vh",
            }}
          />
        </div>
        <div className="col-10">
          {widget.data?.footerTicker ? (
            <marquee>
              <p style={{ fontSize: "25vh" }}>
                {rssFeed.map((feed) => feed.title).join("...## ")}
              </p>
            </marquee>
          ) : (
            <p style={{ fontSize: "12vh", marginLeft: "5vh" }}>{feed.title}</p>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{ width: width + "px", height: height + "px", color, fontFamily }}
      className="overflow-hidden d-flex flex-column justify-content-center align-items-centers"
    >
      <div
        style={{
          color: widget.data.color,
          backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.7),rgba(0,0,0,0.7))`,
          backgroundRepeat: "no-repeat",
          backgroundSize: width + "px " + height + "px",
          fontFamily: widget.data.fontFamily,
          height: "100vh",
          width: "100wh",
        }}
        className="d-flex justify-content-center align-items-end"
      >
        <div
          style={{
            height: "100vh",
            width: "100vw",
          }}
          className="d-flex flex-column justify-content-center align-items-center px-5"
        >
          <span
            className="mb-3 d-block text-center"
            style={{
              fontSize: !widget.data?.showDescription
                ? (window.innerWidth + window.innerHeight) / 30
                : (window.innerWidth + window.innerHeight) / 45,
              fontWeight: "bold",
              lineHeight: 1,
            }}
          >
            {feed.title}
          </span>
          {widget.data?.showDescription && (
            <p
              style={{
                fontSize: (window.innerWidth + window.innerHeight) / 50,
              }}
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: feed?.description,
              }}
            ></p>
          )}
        </div>
      </div>

      <div
        className="position-absolute bottom-0"
        style={{ right: 0, margin: width / 80 + "px" }}
      >
        <img
          src="/images/indiatoday.svg"
          alt="logo"
          style={{
            height: "15vh",
            width: "15vh",
          }}
        />
      </div>
    </div>
  );
};

export default IndiaToday;
