import React, { Fragment, PureComponent } from "react";
import Clock from "react-live-clock";
import { ANALOG_CLOCKS } from "..";
import { getIndividualArea } from "../../../../utils/helper";

class Portrait extends PureComponent {
  state = {
    date: "",
    tz: this.props.widget?.data?.tz,
    ratio: 1,
    height: window.innerHeight,
    width: window.innerWidth,
    hasScrollbar: false,
  };

  componentDidMount = () => {
    this.startInterval();
    // set body background color
    if (this.props.widget.data.bgColor.includes("linear-gradient")) {
      document.body.style.background = this.props.widget.data.bgColor;
    } else document.body.style.backgroundColor = this.props.widget.data.bgColor;
    if (this.props.widget.data.faceId === "1") {
      const { height, width } = getIndividualArea(
        this.props.height,
        this.props.width,
        this.props.widget.data.tz.length
      );
      this.setState({ height, width });
      function scrollbarVisible(element) {
        return element.scrollHeight > element.clientHeight;
      }
      const doc = document.querySelector("#doc");
      const hasScrollbar = scrollbarVisible(doc);
      this.setState({ hasScrollbar });
    }
  };
  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  startInterval = () => {
    this.timer && clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.state.tz.forEach((timezone, index) => {
        var d = new Date();

        var utc = d.getTime() + d.getTimezoneOffset() * 60000;
        d = new Date(utc + 3600000 * timezone.offset);

        const hr = d.getHours();
        const min = d.getMinutes();
        const sec = d.getSeconds();
        const hr_rotation = 30 * hr + min / 2; //converting current time
        const min_rotation = 6 * min;
        const sec_rotation = 6 * sec;

        try {
          document.getElementById(
            "hour" + index + 1
          ).style.transform = `rotate(${hr_rotation}deg)`;
          document.getElementById(
            "minute" + index + 1
          ).style.transform = `rotate(${min_rotation}deg)`;
          document.getElementById(
            "second" + index + 1
          ).style.transform = `rotate(${sec_rotation}deg)`;
        } catch (err) {}
      });
      this.setState({
        date: new Date().toString(),
      });
    }, 1000);
  };

  render() {
    const { fontSize, widget } = this.props;
    const { height, width } = this.state;
    const rows = Math.ceil(widget.data.tz.length / 3);
    const big = window.innerWidth / window.innerHeight > 0.5;

    let cityNames = widget.data.cityNames || [];
    if (
      "cityName0" in widget.data ||
      "cityName1" in widget.data ||
      "cityName2" in widget.data
    ) {
      cityNames = [];
      for (let i = 0; i < 3; i++) {
        if (`cityName${i}` in widget.data) {
          cityNames.push(widget.data[`cityName${i}`]);
        }
      }
    }

    return (
      <Fragment>
        {/* analog */}
        {widget.data.faceId === "1" && widget.data.tz.length === 1 && (
          <div
            className="d-flex align-align-self-start flex-column justify-content-around align-items-center"
            id="myDiv"
            style={{
              height: "100vh",
              // gap: height * 0.01 + "px",
            }}
          >
            {widget?.data?.tz?.map((timezone, index) => {
              var d = new Date();

              var utc = d.getTime() + d.getTimezoneOffset() * 60000;
              d = new Date(utc + 3600000 * timezone.offset).toLocaleDateString(
                "en-in",
                {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }
              );
              return (
                <div
                  // className={widget.data.tz.length <= 2 ? "col-12" : "col-6"}
                  className="d-flex flex-column justify-content-center,"
                  style={{
                    // width: width + "px",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="clockContainer2 m-0"
                    style={{
                      background: `url(${
                        ANALOG_CLOCKS[
                          widget.data?.selectedAnalog?.analogId || 0
                        ]
                      }) no-repeat`,
                      backgroundSize: "100%",
                      height: width * 0.75 + "px",
                      width: width * 0.75 + "px",
                    }}
                  >
                    <div
                      className="hour"
                      id={"hour" + index + 1}
                      style={{
                        background:
                          widget.data?.selectedAnalog?.dialColor || "#000",
                      }}
                    ></div>
                    <div
                      className="minute"
                      id={"minute" + index + 1}
                      style={{
                        background:
                          widget.data?.selectedAnalog?.dialColor || "#000",
                      }}
                    ></div>
                    <div
                      className="second"
                      id={"second" + index + 1}
                      style={{
                        background:
                          widget.data?.selectedAnalog?.dialColor || "#000",
                      }}
                    ></div>
                  </div>
                  <div
                    className="text-center text-capitalize"
                    style={{
                      // fontSize: fontSize / 6.5,
                      fontSize: width * 0.15 + "px",
                      color: widget.data.color,
                      fontFamily: widget.data.fontFamily,
                    }}
                  >
                    {new Date().getSeconds() % 2 === 0 && widget.data.showDate
                      ? d
                      : cityNames[index]}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {widget.data.faceId === "1" &&
          rows === 1 &&
          widget.data.tz.length > 1 && (
            <div
              className="d-flex align-align-self-start flex-column justify-content-around align-items-center"
              id="myDiv"
              style={{
                height: "100vh",
                // gap: height * 0.01 + "px",
              }}
            >
              {widget?.data?.tz?.map((timezone, index) => {
                var d = new Date();

                var utc = d.getTime() + d.getTimezoneOffset() * 60000;
                d = new Date(
                  utc + 3600000 * timezone.offset
                ).toLocaleDateString("en-in", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                });
                return (
                  <div
                    // className={widget.data.tz.length <= 2 ? "col-12" : "col-6"}
                    className="d-flex m-0 p-0 flex-column justify-content-center,"
                    style={{
                      // width: width + "px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="clockContainer2 m-0 p-0"
                      style={{
                        background: `url(${
                          ANALOG_CLOCKS[
                            widget.data?.selectedAnalog?.analogId || 0
                          ]
                        }) no-repeat`,
                        backgroundSize: "100%",
                        height: height * 0.7 + "px",
                        width: height * 0.7 + "px",
                      }}
                    >
                      <div
                        className="hour"
                        id={"hour" + index + 1}
                        style={{
                          background:
                            widget.data?.selectedAnalog?.dialColor || "#000",
                        }}
                      ></div>
                      <div
                        className="minute"
                        id={"minute" + index + 1}
                        style={{
                          background:
                            widget.data?.selectedAnalog?.dialColor || "#000",
                        }}
                      ></div>
                      <div
                        className="second"
                        id={"second" + index + 1}
                        style={{
                          background:
                            widget.data?.selectedAnalog?.dialColor || "#000",
                        }}
                      ></div>{" "}
                    </div>
                    <div
                      className="text-center text-capitalize"
                      style={{
                        // fontSize: fontSize / 6.5,
                        fontSize: height * 0.15 + "px",
                        color: widget.data.color,
                        fontFamily: widget.data.fontFamily,
                      }}
                    >
                      {new Date().getSeconds() % 2 === 0 && widget.data.showDate
                        ? d
                        : cityNames[index]}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        {widget.data.faceId === "1" &&
          big &&
          rows > 1 &&
          widget.data.tz.length === 4 && (
            <div className="row m-0 p-0">
              <div
                className="col-6 d-flex align-align-self-start flex-column justify-content-around align-items-center"
                id="myDiv"
                style={{
                  height: "100vh",

                  // gap: height * 0.01 + "px",
                }}
              >
                {widget?.data?.tz?.slice(0, 2)?.map((timezone, index) => {
                  var d = new Date();

                  var utc = d.getTime() + d.getTimezoneOffset() * 60000;
                  d = new Date(
                    utc + 3600000 * timezone.offset
                  ).toLocaleDateString("en-in", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  });
                  return (
                    <div
                      // className={widget.data.tz.length <= 2 ? "col-12" : "col-6"}
                      className="d-flex flex-column justify-content-center,"
                      style={{
                        // width: width + "px",
                        alignItems: "center",
                      }}
                      key={index}
                    >
                      <div
                        className="clockContainer2 m-0"
                        style={{
                          background: `url(${
                            ANALOG_CLOCKS[
                              widget.data?.selectedAnalog?.analogId || 0
                            ]
                          }) no-repeat`,
                          backgroundSize: "100%",
                          height: height * 1 + "px",
                          width: height * 1 + "px",
                        }}
                      >
                        <div
                          className="hour"
                          id={"hour" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="minute"
                          id={"minute" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="second"
                          id={"second" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>{" "}
                      </div>
                      <div
                        className="text-center text-capitalize"
                        style={{
                          fontSize: width * 0.25 + "px",
                          color: widget.data.color,
                          fontFamily: widget.data.fontFamily,
                        }}
                      >
                        {new Date().getSeconds() % 2 === 0 &&
                        widget.data.showDate
                          ? d
                          : cityNames[index]}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className="col-6 d-flex align-align-self-start flex-column justify-content-around align-items-center"
                id="myDiv"
                style={{
                  height: "100vh",
                  // gap: height * 0.01 + "px",
                }}
              >
                {widget?.data?.tz?.slice(2)?.map((timezone, index) => {
                  index = index + 2;
                  var d = new Date();

                  var utc = d.getTime() + d.getTimezoneOffset() * 60000;
                  d = new Date(
                    utc + 3600000 * timezone.offset
                  ).toLocaleDateString("en-in", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  });
                  return (
                    <div
                      // className={widget.data.tz.length <= 2 ? "col-12" : "col-6"}
                      className="d-flex flex-column justify-content-center,"
                      style={{
                        // width: width + "px",
                        alignItems: "center",
                      }}
                      key={index}
                    >
                      <div
                        className="clockContainer2 m-0"
                        style={{
                          background: `url(${
                            ANALOG_CLOCKS[
                              widget.data?.selectedAnalog?.analogId || 0
                            ]
                          }) no-repeat`,
                          backgroundSize: "100%",
                          height: height * 1 + "px",
                          width: height * 1 + "px",
                        }}
                      >
                        <div
                          className="hour"
                          id={"hour" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="minute"
                          id={"minute" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="second"
                          id={"second" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>{" "}
                      </div>
                      <div
                        className="text-center text-capitalize"
                        style={{
                          fontSize: width * 0.25 + "px",
                          color: widget.data.color,
                          fontFamily: widget.data.fontFamily,
                        }}
                      >
                        {new Date().getSeconds() % 2 === 0 &&
                        widget.data.showDate
                          ? d
                          : cityNames[index]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        {widget.data.faceId === "1" &&
          big &&
          rows > 1 &&
          widget.data.tz.length > 4 && (
            <div className="row m-0 p-0">
              <div
                className="col-6 d-flex align-align-self-start flex-column justify-content-around align-items-center"
                id="myDiv"
                style={{
                  height: "100vh",

                  // gap: height * 0.01 + "px",
                }}
              >
                {widget?.data?.tz?.slice(0, 3)?.map((timezone, index) => {
                  var d = new Date();

                  var utc = d.getTime() + d.getTimezoneOffset() * 60000;
                  d = new Date(
                    utc + 3600000 * timezone.offset
                  ).toLocaleDateString("en-in", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  });
                  return (
                    <div
                      // className={widget.data.tz.length <= 2 ? "col-12" : "col-6"}
                      className="d-flex flex-column justify-content-center,"
                      style={{
                        // width: width + "px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="clockContainer2 m-0"
                        style={{
                          background: `url(${
                            ANALOG_CLOCKS[
                              widget.data?.selectedAnalog?.analogId || 0
                            ]
                          }) no-repeat`,
                          backgroundSize: "100%",
                          height: height * 1.2 + "px",
                          width: height * 1.2 + "px",
                        }}
                      >
                        <div
                          className="hour"
                          id={"hour" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="minute"
                          id={"minute" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="second"
                          id={"second" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>{" "}
                      </div>
                      <div
                        className="text-center text-capitalize"
                        style={{
                          // fontSize: fontSize / 6.5,
                          fontSize: width * 0.25 + "px",
                          color: widget.data.color,
                          fontFamily: widget.data.fontFamily,
                        }}
                      >
                        {new Date().getSeconds() % 2 === 0 &&
                        widget.data.showDate
                          ? d
                          : cityNames[index]}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className="col-6 d-flex align-align-self-start flex-column justify-content-around align-items-center"
                id="myDiv"
                style={{
                  height: "100vh",
                  // gap: height * 0.01 + "px",
                }}
              >
                {widget?.data?.tz?.slice(3)?.map((timezone, index) => {
                  index = index + 3;
                  var d = new Date();

                  var utc = d.getTime() + d.getTimezoneOffset() * 60000;
                  d = new Date(
                    utc + 3600000 * timezone.offset
                  ).toLocaleDateString("en-in", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  });
                  return (
                    <div
                      // className={widget.data.tz.length <= 2 ? "col-12" : "col-6"}
                      className="d-flex flex-column justify-content-center,"
                      style={{
                        // width: width + "px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="clockContainer2 m-0"
                        style={{
                          background: `url(${
                            ANALOG_CLOCKS[
                              widget.data?.selectedAnalog?.analogId || 0
                            ]
                          }) no-repeat`,
                          backgroundSize: "100%",
                          height: height * 1.2 + "px",
                          width: height * 1.2 + "px",
                        }}
                      >
                        <div
                          className="hour"
                          id={"hour" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="minute"
                          id={"minute" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="second"
                          id={"second" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>{" "}
                      </div>
                      <div
                        className="text-center text-capitalize"
                        style={{
                          // fontSize: fontSize / 6.5,
                          fontSize: width * 0.25 + "px",
                          color: widget.data.color,
                          fontFamily: widget.data.fontFamily,
                        }}
                      >
                        {new Date().getSeconds() % 2 === 0 &&
                        widget.data.showDate
                          ? d
                          : cityNames[index]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        {widget.data.faceId === "1" && rows > 1 && !big && (
          <div
            className="d-flex align-align-self-start flex-column justify-content-around align-items-center"
            id="myDiv"
            style={{
              height: "100vh",
              // gap: height * 0.01 + "px",
            }}
          >
            {widget?.data?.tz?.map((timezone, index) => {
              var d = new Date();

              var utc = d.getTime() + d.getTimezoneOffset() * 60000;
              d = new Date(utc + 3600000 * timezone.offset).toLocaleDateString(
                "en-in",
                {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }
              );
              return (
                <div
                  // className={widget.data.tz.length <= 2 ? "col-12" : "col-6"}
                  className="d-flex flex-column justify-content-center,"
                  style={{
                    // width: width + "px",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="clockContainer2 m-0"
                    style={{
                      background: `url(${
                        ANALOG_CLOCKS[
                          widget.data?.selectedAnalog?.analogId || 0
                        ]
                      }) no-repeat`,
                      backgroundSize: "100%",
                      height: height * 0.75 + "px",
                      width: height * 0.75 + "px",
                    }}
                  >
                    <div
                      className="hour"
                      id={"hour" + index + 1}
                      style={{
                        background:
                          widget.data?.selectedAnalog?.dialColor || "#000",
                      }}
                    ></div>
                    <div
                      className="minute"
                      id={"minute" + index + 1}
                      style={{
                        background:
                          widget.data?.selectedAnalog?.dialColor || "#000",
                      }}
                    ></div>
                    <div
                      className="second"
                      id={"second" + index + 1}
                      style={{
                        background:
                          widget.data?.selectedAnalog?.dialColor || "#000",
                      }}
                    ></div>{" "}
                  </div>
                  <div
                    className="text-center text-capitalize"
                    style={{
                      // fontSize: fontSize / 6.5,
                      fontSize: width * 0.35 + "px",
                      color: widget.data.color,
                      fontFamily: widget.data.fontFamily,
                    }}
                  >
                    {new Date().getSeconds() % 2 === 0 && widget.data.showDate
                      ? d
                      : cityNames[index]}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* digital */}
        {widget.data.faceId === "2" && (
          <div
            className="row g-0 w-100 justify-content-stretch align-items-stretch m-0"
            style={{
              height: "100vh",
            }}
          >
            {widget?.data?.tz?.map((timezone, index) => {
              var d = new Date();

              var utc = d.getTime() + d.getTimezoneOffset() * 60000;
              d = new Date(utc + 3600000 * timezone.offset);

              const twentyFourHour = !!widget?.data?.twentyFourHourFormat;
              const formattedTime = d.toLocaleTimeString([], {
                hour: "2-digit",
                hourCycle: twentyFourHour ? "h24" : "h12",
                minute: "2-digit",
              });
              const formattedDate = d.toLocaleDateString("en-in", {
                day: "numeric",
                month: "short",
                year: "numeric",
              });
              return (
                <div
                  className={
                    "text-center " +
                    (widget.data.tz.length - 1 === index
                      ? widget.data.tz.length % 2 !== 0
                        ? "col-12"
                        : "col-6"
                      : "col-6")
                  }
                  key={index}
                >
                  <div
                    key={this.state.date + index}
                    className="card m-0 p-0 d-flex justify-content-center align-items-center h-100 text-uppercase"
                    style={{
                      background: widget.data.bgColor,
                      color: widget.data.color,
                      fontFamily: widget.data.fontFamily,
                      borderRadius: "0px",
                      border: "none",
                      height: (window.innerHeight - 100) / 3 + "px",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: widget.data.fontFamily,
                        fontSize: twentyFourHour
                          ? widget.data.tz.length === 1
                            ? width * 0.2
                            : width * 0.18 + "px"
                          : widget.data.tz.length === 1
                          ? width * 0.2 + "px"
                          : width * 0.1 + "px",
                        lineHeight:
                          widget.data.tz.length === 1
                            ? width * 0.3 + "px"
                            : width * 0.2 + "px",
                      }}
                    >
                      {formattedTime}
                    </div>

                    <div
                      className="text-center text-capitalize"
                      style={{
                        fontFamily: widget.data.fontFamily,
                        fontSize: twentyFourHour
                          ? widget.data.tz.length === 1
                            ? width * 0.15 + "px"
                            : width * 0.1 + "px"
                          : widget.data.tz.length === 1
                          ? width * 0.15 + "px"
                          : width * 0.08 + "px",
                      }}
                    >
                      {widget.data?.showDate && (
                        <p
                          style={{
                            fontSize: twentyFourHour
                              ? widget.data.tz.length === 1
                                ? width * 0.1 + "px"
                                : width * 0.06 + "px"
                              : widget.data.tz.length === 1
                              ? width * 0.1 + "px"
                              : width * 0.05 + "px",
                          }}
                          className="m-0 p-0"
                        >
                          {formattedDate}
                        </p>
                      )}
                      {cityNames[index]}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Fragment>
    );
  }
}

export default Portrait;
