import React, { PureComponent } from "react";
import { getInformalDateTime, getIndianNumber } from "../../../../utils/helper";

const Tweet = ({ user, tweet, fontFamily }) => {
  let width = window.innerWidth;
  if (window.innerHeight > window.innerWidth) {
    width = window.innerHeight;
  }

  const username = user?.username;
  const name = user?.name;
  const profileImg = user?.profile_image_url.replace("_normal", "");
  const verified = user?.verified;

  const like = getIndianNumber(tweet.public_metrics.like_count);
  const comment = getIndianNumber(tweet.public_metrics.reply_count);
  const retweet = getIndianNumber(tweet.public_metrics.retweet_count);
  const createdAt = tweet?.created_at;
  const formattedDate = getInformalDateTime(createdAt);
  const medias = tweet?.media; //[]
  const text = tweet?.text || "";

  return (
    <div
      className="bg-white p-2 m-2"
      style={{
        borderRadius: width / 90 + "px",
      }}
    >
      <div className="px-4 mx-4">
        <div className="d-flex align-items-center">
          <img
            src={profileImg}
            alt="user"
            style={{
              borderRadius: "50%",
              height: width / 18 + "px",
              width: width / 18 + "px",
            }}
          />
          <div className="ms-4 w-100">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{
                fontFamily: fontFamily || "sans-serif",
              }}
            >
              <div className="d-flex align-items-center justify-content-center">
                <p
                  className="p-0 m-0"
                  style={{
                    fontSize: width / 55 + "px",
                    fontWeight: "600",
                    fontFamily: fontFamily || "sans-serif",
                  }}
                >
                  {name}
                </p>

                {verified && (
                  <div
                    className="ms-3"
                    style={{ fontFamily: fontFamily || "sans-serif" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#1d9bf0"
                      height={width / 52}
                      width={width / 52}
                      viewBox="0 0 512 512"
                    >
                      <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" />
                    </svg>
                  </div>
                )}

                <div
                  className="ms-4"
                  style={{
                    fontSize: width / 72 + "px",
                    color: "#8a8686",
                    fontFamily: fontFamily || "sans-serif",
                  }}
                >
                  <span>&#x2022;</span>
                  <span className="ms-2">{formattedDate}</span>
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#1d9bf0"
                  height={width / 36}
                  width={width / 36}
                  viewBox="0 0 512 512"
                >
                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                </svg>
              </div>
            </div>

            <p
              className="text-info p-0 m-0"
              style={{
                fontSize: width / 75 + "px",
                fontFamily: fontFamily || "sans-serif",
              }}
            >
              @{username}
            </p>
          </div>
        </div>

        <div className="row mt-4">
          <div className={medias ? "col-md-6" : "col-md-12"}>
            <p
              className=""
              style={{
                fontSize: width / 38 + "px",
                fontFamily: fontFamily || "sans-serif",
              }}
            >
              {text}
            </p>
          </div>

          {medias && (
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              {medias.map((media, i) => {
                const variants = media?.variants || [];
                const video =
                  variants &&
                  variants
                    .filter((v) => v.content_type === "video/mp4")
                    .sort((a, b) => b.bit_rate - a.bit_rate)[0];
                return media.type === "photo" ? (
                  <div className="" key={media.media_key}>
                    <img
                      style={{
                        borderRadius: width / 90 + "px",
                        height: width / 3 + "px",
                        width: "100%",
                      }}
                      src={media.url}
                      alt="media"
                    />
                  </div>
                ) : (
                  <video
                    key={media.media_key}
                    style={{
                      height: width / 4 + "px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                    loop
                    autoPlay
                    muted
                    controls={false}
                  >
                    <source src={video.url}></source>
                  </video>
                );
              })}
            </div>
          )}
        </div>

        <div
          className="d-flex justify-content-between align-items-center mt-2"
          style={{ color: "#8a8686", fontFamily: fontFamily || "sans-serif" }}
        >
          <div className="d-flex align-items-center mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#1d9bf0"
              height={width / 52}
              width={width / 52}
              viewBox="0 0 512 512"
            >
              <path d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z" />
            </svg>

            <span
              className="ms-2"
              style={{
                fontSize: width / 52 + "px",
                fontFamily: fontFamily || "sans-serif",
              }}
            >
              {comment}
            </span>
          </div>

          <div className="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#1d9bf0"
              height={width / 52}
              width={width / 52}
              viewBox="0 0 640 512"
            >
              <path d="M614.2 334.8C610.5 325.8 601.7 319.1 592 319.1H544V176C544 131.9 508.1 96 464 96h-128c-17.67 0-32 14.31-32 32s14.33 32 32 32h128C472.8 160 480 167.2 480 176v143.1h-48c-9.703 0-18.45 5.844-22.17 14.82s-1.656 19.29 5.203 26.16l80 80.02C499.7 445.7 505.9 448 512 448s12.28-2.344 16.97-7.031l80-80.02C615.8 354.1 617.9 343.8 614.2 334.8zM304 352h-128C167.2 352 160 344.8 160 336V192h48c9.703 0 18.45-5.844 22.17-14.82s1.656-19.29-5.203-26.16l-80-80.02C140.3 66.34 134.1 64 128 64S115.7 66.34 111 71.03l-80 80.02C24.17 157.9 22.11 168.2 25.83 177.2S38.3 192 48 192H96V336C96 380.1 131.9 416 176 416h128c17.67 0 32-14.31 32-32S321.7 352 304 352z" />
            </svg>

            <span className="ms-2" style={{ fontSize: width / 52 + "px" }}>
              {retweet}
            </span>
          </div>

          <div className="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#1d9bf0"
              height={width / 52}
              width={width / 52}
              viewBox="0 0 512 512"
            >
              <path d="M0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L256 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 .0003 232.4 .0003 190.9L0 190.9z" />
            </svg>
            <span className="ms-2" style={{ fontSize: width / 52 + "px" }}>
              {like}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

class Twitter extends PureComponent {
  state = {
    tweet: null,
    tweetData: this.props.widget.twitterData,
    tweetIndex: 0,
    tweetCount: 0,
    tweetDuration: this.props.widget.data.tweetDuration * 1000,
  };

  componentDidMount = () => {
    this.startTweets();
  };

  startTweets = () => {
    const tweets = this.state.tweetData;

    if (tweets) {
      if (tweets.length - 1 === this.state.tweetIndex) {
        this.changeTweet(0);
      } else {
        this.changeTweet(this.state.tweetIndex + 1);
      }
    }
  };

  changeTweet = (index) => {
    const tweets = this.state.tweetData;

    const tweet = tweets[index];

    const duration =
      tweets.length - 1 >= this.state.tweetIndex
        ? tweet.media
          ? tweet.media[0].duration_ms
          : this.state.tweetDuration
        : 10;

    if (tweets) {
      this.setState({
        tweet: tweets[index],
        tweetIndex: index,
      });
    }
    setTimeout(() => {
      this.startTweets();
    }, duration);
  };

  componentWillUnmount = () => {
    clearTimeout(this.startTweets);
  };

  render() {
    const widget = this.props.widget;
    const { tweetData, tweet } = this.state;

    return (
      <section
        className="h-100 d-flex flex-column justify-content-center p-2 overflow-hidden"
        style={{
          fontFamily: widget.data.fontFamily || "sans-serif",
          background: widget.data.bgColor || "#fff",
        }}
      >
        <div className="row ">
          <div className="col-md-12">
            <div
              id="myCarousel"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {tweet && (
                  <Tweet
                    key={tweet.id}
                    user={tweet.user}
                    tweet={tweet.tweet}
                    fontFamily={widget.data.fontFamily || "sans-serif"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Twitter;
