import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./source/Home";

function App() {
  useEffect(() => {
    window.addEventListener("resize", () => window.location.reload());
  }, []);

  return (
    <Router>
      {/* routes */}
      <Routes>
        <Route path="/widget/:id" element={<Home />} />
        <Route path="/widgetpreview/:id" element={<Home />} />
      </Routes>
    </Router>
  );
}
export default App;
