import React, { Fragment, PureComponent } from "react";
import { getIndividualArea } from "../../../../utils/helper";
import { ANALOG_CLOCKS } from "..";

class Landscape extends PureComponent {
  state = {
    date: "",
    tz: this.props.widget?.data?.tz,
    height: window.innerHeight,
    width: window.innerWidth,
    hasScrollbar: false,
  };

  componentDidMount = () => {
    this.startInterval();

    // set body background color
    if (this.props.widget.data.bgColor.includes("linear-gradient")) {
      document.body.style.background = this.props.widget.data.bgColor;
    } else document.body.style.backgroundColor = this.props.widget.data.bgColor;

    function scrollbarVisible(element) {
      return element.scrollHeight > element.clientHeight;
    }
    const doc = document.querySelector("#doc");
    const hasScrollbar = scrollbarVisible(doc);
    const { height, width } = getIndividualArea(
      this.props.width,
      this.props.height,
      this.props.widget.data.tz.length
    );
    this.setState({ height, width, hasScrollbar });
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  startInterval = () => {
    this.timer && clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.state.tz.forEach((timezone, index) => {
        var d = new Date();

        var utc = d.getTime() + d.getTimezoneOffset() * 60000;
        d = new Date(utc + 3600000 * timezone.offset);

        const hr = d.getHours();
        const min = d.getMinutes();
        const sec = d.getSeconds();
        const hr_rotation = 30 * hr + min / 2; //converting current time
        const min_rotation = 6 * min;
        const sec_rotation = 6 * sec;

        try {
          document.getElementById(
            "hour" + index + 1
          ).style.transform = `rotate(${hr_rotation}deg)`;
          document.getElementById(
            "minute" + index + 1
          ).style.transform = `rotate(${min_rotation}deg)`;
          document.getElementById(
            "second" + index + 1
          ).style.transform = `rotate(${sec_rotation}deg)`;
        } catch (err) {}
      });
      this.setState({
        date: new Date().toString(),
      });
    }, 1000);
  };

  render() {
    const { fontSize, widget, height: windowHeight } = this.props;
    const { height, width } = this.state;
    const rows = Math.ceil(widget.data.tz.length / 3);
    const rowsArr = rows === 1 ? [1] : [1, 2];
    let cityNames = widget.data.cityNames || [];
    if (
      "cityName0" in widget.data ||
      "cityName1" in widget.data ||
      "cityName2" in widget.data
    ) {
      cityNames = [];
      for (let i = 0; i < 3; i++) {
        if (`cityName${i}` in widget.data) {
          cityNames.push(widget.data[`cityName${i}`]);
        }
      }
    }
    return (
      <Fragment>
        {/* analog */}

        {widget.data.faceId === "1" &&
          widget.data.tz.length !== 4 &&
          rowsArr.map((row) => (
            <div
              key={row}
              className="d-flex justify-content-around align-items-center m-0 p-0"
              style={{
                height: widget?.data?.tz.length > 3 ? "50vh" : "100vh",
                gap:
                  widget?.data?.tz.length === 2
                    ? width * 0 + "px"
                    : widget?.data?.tz.length > 3
                    ? width * 0.1 + "px"
                    : width * 0.1 + "px",
              }}
            >
              {widget?.data?.tz
                ?.slice((row - 1) * 3, row * 3)
                ?.map((timezone, index) => {
                  index = row === 1 ? index : index + 3;
                  var d = new Date();

                  var utc = d.getTime() + d.getTimezoneOffset() * 60000;
                  d = new Date(
                    utc + 3600000 * timezone.offset
                  ).toLocaleDateString("en-in", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  });
                  return (
                    <div
                      className={
                        widget.data.tz.length === 1
                          ? "d-flex flex-column align-items-center"
                          : widget.data.tz.length === 2
                          ? "d-flex flex-column justify-content-center align-items-center"
                          : "d-flex flex-column justify-content-center align-items-center"
                      }
                    >
                      <div
                        className="clockContainer2 m-0"
                        style={{
                          background: `url(${
                            ANALOG_CLOCKS[
                              widget.data?.selectedAnalog?.analogId || 0
                            ]
                          }) no-repeat`,
                          backgroundSize: "100%",
                          height:
                            widget.data.tz.length === 1
                              ? width * 0.8 + "px"
                              : widget.data.tz.length === 2
                              ? width * 0.95 + "px"
                              : widget.data.tz.length === 3
                              ? width * 1.05 + "px"
                              : widget.data.tz.length > 4
                              ? width * 1.8 + "px"
                              : width * 1 + "px",
                          width:
                            widget.data.tz.length === 1
                              ? width * 0.8 + "px"
                              : widget.data.tz.length === 2
                              ? width * 0.95 + "px"
                              : widget.data.tz.length === 3
                              ? width * 1.05 + "px"
                              : widget.data.tz.length > 4
                              ? width * 1.8 + "px"
                              : width * 1 + "px",
                        }}
                      >
                        <div
                          className="hour"
                          id={"hour" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="minute"
                          id={"minute" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="second"
                          id={"second" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                      </div>
                      <div
                        className="text-center m-0 p-0 text-capitalize"
                        style={{
                          color: widget.data.color,
                          fontFamily: widget.data.fontFamily,
                          fontSize:
                            widget.data.tz.length === 1
                              ? width * 0.1 + "px"
                              : width * 0.17 + "px",
                        }}
                      >
                        {new Date().getSeconds() % 2 === 0 &&
                        widget.data.showDate
                          ? d
                          : cityNames[index]}
                      </div>
                    </div>
                  );
                })}
            </div>
          ))}

        {widget.data.faceId === "1" &&
          widget.data.tz.length === 4 &&
          rowsArr.map((row) => (
            <div
              key={row}
              className="d-flex justify-content-around align-items-center m-0 p-0"
              style={{
                height: "80vh",
              }}
            >
              {widget?.data?.tz
                ?.slice((row - 1) * 2, row * 2)
                ?.map((timezone, index) => {
                  index = row === 1 ? index : index + 2;
                  var d = new Date();

                  var utc = d.getTime() + d.getTimezoneOffset() * 60000;
                  d = new Date(
                    utc + 3600000 * timezone.offset
                  ).toLocaleDateString("en-in", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  });
                  return (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div
                        className="clockContainer2 m-0"
                        style={{
                          height: width * 1.6 + "px",
                          width: width * 1.6 + "px",
                          background: `url(${
                            ANALOG_CLOCKS[
                              widget.data?.selectedAnalog?.analogId || 0
                            ]
                          }) no-repeat`,
                          backgroundSize: "100%",
                        }}
                      >
                        <div
                          className="hour"
                          id={"hour" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="minute"
                          id={"minute" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                        <div
                          className="second"
                          id={"second" + index + 1}
                          style={{
                            background:
                              widget.data?.selectedAnalog?.dialColor || "#000",
                          }}
                        ></div>
                      </div>
                      <div
                        className="text-center m-0 p-0 text-capitalize"
                        style={{
                          color: widget.data.color,
                          fontFamily: widget.data.fontFamily,
                          fontSize: width * 0.2 + "px",
                        }}
                      >
                        {new Date().getSeconds() % 2 === 0 &&
                        widget.data.showDate
                          ? d
                          : cityNames[index]}
                      </div>
                    </div>
                  );
                })}
            </div>
          ))}

        {/* digital */}
        {widget.data.faceId === "2" && (
          <div
            className="row g-0 w-100 justify-content-stretch align-items-stretch m-0"
            style={{
              height: "100vh",
            }}
          >
            {widget?.data?.tz?.map((timezone, index) => {
              var d = new Date();
              const twentyFourHour = !!widget?.data?.twentyFourHourFormat;
              var utc = d.getTime() + d.getTimezoneOffset() * 60000;
              d = new Date(utc + 3600000 * timezone.offset);
              const formattedTime = d.toLocaleTimeString([], {
                hour: "2-digit",
                hourCycle: twentyFourHour ? "h24" : "h12",
                minute: "2-digit",
              });
              const formattedDate = d.toLocaleDateString("en-in", {
                day: "numeric",
                month: "short",
                year: "numeric",
              });
              return (
                <div
                  className={
                    "text-center " +
                    (widget.data.tz.length - 1 === index
                      ? widget.data.tz.length % 2 !== 0
                        ? "col-12"
                        : "col-6"
                      : "col-6")
                  }
                  key={index}
                >
                  <div
                    className="card m-0 p-0 d-flex justify-content-center align-items-center h-100"
                    style={{
                      background: widget.data.bgColor,
                      color: widget.data.color,
                      fontFamily: widget.data.fontFamily,
                      borderRadius: "0px",
                      border: "none",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: widget.data.fontFamily,
                        fontSize: !widget.data.showDate
                          ? widget.data.tz.length === 1
                            ? width * 1.6 + "%"
                            : twentyFourHour
                            ? width * 1.5 + "%"
                            : width * 1.5 + "%"
                          : widget.data.tz.length === 1
                          ? width * 1.9 + "%"
                          : width * 1.9 + "%",
                      }}
                      key={this.state.date + index}
                      className="text-uppercase"
                    >
                      {formattedTime}
                    </div>

                    <div
                      className="text-center m-0 text-capitalize p-0"
                      style={{
                        fontFamily: widget.data?.fontFamily,
                        fontSize:
                          widget.data.tz.length === 1
                            ? width * 0.3 + "%"
                            : width * 0.7 + "%",
                      }}
                    >
                      {widget.data?.showDate && (
                        <p
                          style={{ fontSize: width * 0.5 + "%" }}
                          className="m-0 p-0"
                        >
                          {formattedDate}
                        </p>
                      )}
                      {cityNames[index]}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Fragment>
    );
  }
}

export default Landscape;
