import React, { PureComponent } from "react";
import { getIndividualArea } from "../../../../utils/helper";
import { imgPath, gradientColors } from "./imgData";

class Landscape extends PureComponent {
  state = {
    fontSize: window.innerWidth / 24,
  };

  componentDidMount() {}

  render() {
    const { widget } = this.props;
    const height = window.innerHeight;
    const { height: individualHeight, width: individualWidth } =
      getIndividualArea(
        window.innerHeight,
        window.innerWidth,
        widget?.aqiData?.length || 1
      );

    const isForecast = !!widget?.data?.isForecast;

    const dayOrNight =
      new Date().getHours() >= 18 || new Date().getHours() <= 6
        ? "night"
        : "day";

    const portraitOrLandscapeImgPath =
      individualWidth < window.innerHeight ? "portrait" : "landscape";
    return (
      <div
        style={{
          width: window.innerWidth + "px",
        }}
      >
        <div
          className="d-flex justify-content-center align-items-center m-0 p-0 h-100"
          style={{
            width: window.innerWidth + "px",
          }}
        >
          {widget.aqiData.map((aqi, index) => {
            const Aqi = aqi;
            const percentage = (Aqi.aqi / 500) * 100;
            const iAqi = aqi.iaqi
            // const secondaryColor = widget.data.secondaryColor
            // const primaryColor = widget.data.primaryColor
            const city = Aqi.city[0];
            const forecast = aqi.forecast || [];
            const todayaqi = Aqi.aqi && Aqi.aqi[0];
            let description =
              todayaqi && todayaqi.main && todayaqi.main.toLowerCase();
            let tempIndex = 0;
            if (percentage <= 25) tempIndex = 0;
            if (percentage > 25 && percentage <= 60) tempIndex = 1;
            if (percentage > 60) tempIndex = 2;
            if (["Healthy"].includes(description)) description = "Healthy";
            if (["Unhealthy"].includes(description)) description = "Unhealthy";
            if (["Hazardous"].includes(description)) description = "Hazardous";
            return (
              <div
                className={`card w-100 h-100 border-0 p-0 m-0 position-relative aqi-card aqi `}
                style={{
                  borderRadius: "0px",
                  fontFamily: widget.data.fontFamily,
                  backgroundImage: gradientColors[tempIndex],
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  display: "inline-block",
                  // height: height + "px",
                }}
                key={index}
              >
                <div
                  className="card-body p-0 position-relative"
                  style={{ zIndex: 100 }}
                >
                  <div
                    className={`d-flex flex-column w-100 ${"justify-content-center"} align-items-center`}
                    style={{
                      height: window.innerHeight + "px",
                    }}
                  >
                    <div
                      style={{
                        marginBottom:
                        widget.aqiData.length === 1
                            ? height * 0.1 + "px"
                            : widget.aqiData.length === 2
                            ? height * 0.1 + "px"
                            : height * 0.1 + "px",
                      }}
                      className="me-2 text-center"
                    >
                      <div
                        style={{
                          fontSize:
                            widget.aqiData.length === 1
                              ? individualHeight * 0.06 + "px"
                              : widget.aqiData.length === 2
                              ? individualWidth * 0.07 + "px"
                              : individualWidth * 0.15 + "px",
                          borderRadius: "20px"
                        }}
                      >
                        {percentage <= 25
              ? "Healthy"
              : percentage > 25 && percentage <= 60
              ? "Unhealthy"
              : "Hazardous"}
                      </div>
                      <div
                        style={{
                          fontSize:
                          widget.aqiData.length === 1
                              ? individualHeight > individualWidth
                                ? individualWidth * 0.25 + "px"
                                : individualHeight * 0.22 + "px"
                              : widget.aqiData.length === 2
                              ? individualHeight * 0.32 + "px"
                              : individualWidth * 0.25 + "px",
                          fontWeight: "bold"
                        }}
                      >
                        {Aqi.aqi}
                      </div>
                      <span
                        style={{
                          fontSize:
                            widget.aqiData.length === 1
                              ? individualHeight > individualWidth
                                ? individualWidth * 0.08 + "px"
                                : individualHeight * 0.06 + "px"
                              : widget.aqiData.length === 2
                              ? individualWidth * 0.08 + "px"
                              : individualWidth * 0.15 + "px",
                          marginBottom: individualWidth * 0.01 + "px",
                          fontWeight: "bold"
                        }}
                        className="d-block"
                      >
                        {Aqi.city.toUpperCase() + city.slice(1)}
                      </span>
                    </div>

                    {!isForecast ? (
                      <div
                      >
                        {/* Environment & Wind */}
                        <div
                          className="card mb-1 w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.aqiData.length === 1
                                ? individualHeight > individualWidth
                                  ? individualWidth * 0.04 + "px"
                                  : individualHeight * 0.045 + "px"
                                : widget.aqiData.length === 2
                                ? individualWidth * 0.05 + "px"
                                : individualWidth * 0.05 + "px",
                          }}
                        >
                          <div
                            className="card-body px-0"
                            style={{
                              paddingTop:
                                widget.aqiData.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.aqiData.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              paddingBottom:
                                widget.aqiData.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.aqiData.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              width: individualWidth / 1.5 + "px",
                            }}
                          >
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex text-black justify-content-around align-items-center"
                                style={{ width: "33.3%" ,
                              }}
                              >
                                <span
                                style={{
                                marginRight: "12px",
                                color:"#666"
                                }}>
                                  PM2.5
                                </span>
                                
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#666"}}>{iAqi?.pm25?.v || 0}</span>
                              </div>
                              <div className="border text-black"></div>
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33.3%" }}
                              >
                                <span className=""
                                style={{
                                marginRight: "5px",
                                color:"#666"}}>
                                PM10
                                </span>
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#555"}}>{iAqi?.pm10?.v || 0}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* humidity and air pressure */}
                        <div
                          className="card mb-1 w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.aqiData.length === 1
                                ? individualHeight > individualWidth
                                  ? individualWidth * 0.04 + "px"
                                  : individualHeight * 0.045 + "px"
                                : widget.aqiData.length === 2
                                ? individualWidth * 0.05 + "px"
                                : individualWidth * 0.05 + "px",
                          }}
                        >
                          <div
                            className="card-body px-0"
                            style={{
                              paddingTop:
                                widget.aqiData.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.aqiData.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              paddingBottom:
                                widget.aqiData.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.aqiData.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              width: individualWidth / 1.5 + "px",
                            }}
                          >
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33.3%" }}
                              >
                                 <span className=""
                                style={{
                                marginRight: "18px",
                                color:"#666"
                                }}>
                                SO2
                                </span>
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#555"}}>{iAqi?.so2?.v || 0}</span>
                              </div>
                              <div className="border"></div>
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33.3%" }}
                              >
                                 <span className=""
                                style={{
                                marginRight: "18px",
                                color:"#666"}}>
                                O3
                                </span>
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#555"}}>{iAqi?.o3?.v || 0}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* sun rise and sun set */}
                        <div
                          className="card w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.aqiData.length === 1
                                ? individualHeight > individualWidth
                                  ? individualWidth * 0.04 + "px"
                                  : individualHeight * 0.045 + "px"
                                : widget.aqiData.length === 2
                                ? individualWidth * 0.05 + "px"
                                : individualWidth * 0.05 + "px",
                          }}
                        >
                          <div
                            className="card-body px-0"
                            style={{
                              paddingTop:
                                widget.aqiData.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.aqiData.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              paddingBottom:
                                widget.aqiData.length === 1
                                  ? individualWidth * 0.004 + "px"
                                  : widget.aqiData.length === 2
                                  ? individualWidth * 0.007 + "px"
                                  : individualWidth * 0.02 + "px",
                              width: individualWidth / 1.5 + "px",
                            }}
                          >
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33.3%" }}
                              >
                                 <span className=""
                                style={{
                                marginRight: "18px",
                                color:"#666"}}>
                                NO2
                                </span>
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#555"}}>{iAqi?.no2?.v || 0}</span>
                              </div>
                              <div className="border"></div>
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33.3%" }}
                              >
                                 <span className=""
                                style={{
                                marginRight: "18px",
                                color:"#666"}}>
                                CO
                                </span>
                                <span className=""
                                style={{fontWeight:"bold",
                                color:"#555"}}>{iAqi?.co?.v || 0}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          widget.aqiData.length === 1
                            ? individualHeight > individualWidth
                              ? "w-75"
                              : "w-50"
                            : "w-75"
                        }
                        style={{
                          color:
                            Aqi.main.temp > 35 ? "#ff7e5f" : "#23282c",
                          background: "rgba(255, 255, 255, 1)",
                          borderRadius: individualWidth * 0.02 + "px",
                        }}
                      >
                        <div
                          className="card-body"
                          style={{
                            padding:
                              widget.aqiData.length === 1
                                ? individualHeight * 0.02 + "px"
                                : widget.aqiData.length === 2
                                ? individualHeight * 0.02 + "px"
                                : individualHeight * 0.02 + "px",
                          }}
                        >
                          {/* today data */}
                          <div
                            style={{
                              fontSize:
                                widget.aqiData.length === 1
                                  ? individualHeight > individualWidth
                                    ? individualWidth * 0.05 + "px"
                                    : individualHeight * 0.04 + "px"
                                  : widget.aqiData.length === 2
                                  ? individualHeight * 0.07 + "px"
                                  : individualWidth * 0.07 + "px",
                              // gap: individualWidth * 0.02 + "px",
                              marginBottom: individualHeight * 0.03 + "px",
                            }}
                          >
                            <div className="d-flex justify-content-between">
                              <div
                                className="d-flex justify-content-center w-75"
                                style={{ marginLeft: "auto" }}
                              >
                                <span>
                                  {description === "clouds" ? (
                                    <i className="bi bi-cloud"></i>
                                  ) : description === "clear" ? (
                                    <i className="bi bi-brightness-high"></i>
                                  ) : description === "rain" ? (
                                    <i className="bi bi-cloud-drizzle"></i>
                                  ) : description === "haze" ? (
                                    <i className="bi bi-cloud-haze2"></i>
                                  ) : description === "snow" ? (
                                    <i className="bi bi-cloud-snow"></i>
                                  ) : description === "thunderstorm" ? (
                                    <i className="bi bi-thunderstorm"></i>
                                  ) : (
                                    <i className="bi bi-brightness-high"></i>
                                  )}
                                </span>

                                <span
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                  className="text-capitalize"
                                >
                                  {description.toLowerCase()}
                                </span>
                              </div>
                              <div
                                className="d-flex justify-content-center w-75"
                                // style={{ justifySelf: "end" }}
                              >
                                <span className="">
                                  <i className="bi bi-wind"></i>
                                </span>
                                <span
                                  style={{
                                    // marginRight: "auto",
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {Aqi.wind.speed}
                                </span>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between">
                              <div
                                className="d-flex justify-content-center w-75"
                                // style={{ justifySelf: "end" }}
                              >
                                <span className="">
                                  <i className="bi bi-sunrise"></i>
                                </span>
                                <span
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {city.sunrise || ""}
                                </span>
                              </div>
                              <div
                                className="d-flex justify-content-center w-75"
                                // style={{ justifySelf: "end" }}
                              >
                                <span className="">
                                  <i className="bi bi-sunset"></i>
                                </span>
                                <span
                                  style={{
                                    // marginRight: "auto",
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {city.sunrise || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* Forecast */}
                          <div
                            className="d-flex justify-content-around"
                            style={{
                              margin:
                                widget.aqiData.length === 1 &&
                                individualWidth * 0.01 + "px 0",
                            }}
                          >
                            {forecast.map((data, index) => {
                              const description =
                                data.aqi &&
                                data.aqi[0].main &&
                                data.aqi[0].main.toLowerCase();
                              const day = new Date(
                                data.dt * 1000
                              ).toLocaleDateString("en-US", {
                                weekday: "short",
                              });
                              const temperature = data.main.temp;
                              return (
                                <div
                                  className="d-flex flex-column justify-content-center align-items-center"
                                  key={index}
                                  style={{
                                    background: "#b6bbc7",
                                    borderRadius: individualWidth * 0.02 + "px",
                                    padding:
                                      widget.aqiData.length === 1
                                        ? individualWidth * 0.001 + "px"
                                        : individualWidth * 0.01 + "px",
                                    width: "30%",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize:
                                        widget.aqiData.length === 1
                                          ? individualHeight * 0.025 + "px"
                                          : individualWidth * 0.05 + "px",
                                    }}
                                  >
                                    {description === "clouds" ? (
                                      <i className="bi bi-cloud"></i>
                                    ) : description === "clear" ? (
                                      <i className="bi bi-brightness-high"></i>
                                    ) : description === "rain" ? (
                                      <i className="bi bi-cloud-drizzle"></i>
                                    ) : description === "haze" ? (
                                      <i className="bi bi-cloud-haze2"></i>
                                    ) : description === "snow" ? (
                                      <i className="bi bi-cloud-snow"></i>
                                    ) : description === "thunderstorm" ? (
                                      <i className="bi bi-thunderstorm"></i>
                                    ) : (
                                      <i className="bi bi-brightness-high"></i>
                                    )}
                                  </span>
                                  <span
                                    style={{
                                      fontSize:
                                        widget.aqiData.length === 1
                                          ? individualHeight > individualWidth
                                            ? individualWidth * 0.04 + "px"
                                            : individualHeight * 0.025 + "px"
                                          : widget.aqiData.length === 2
                                          ? individualWidth * 0.03 + "px"
                                          : individualWidth * 0.04 + "px",
                                    }}
                                  >
                                    {day}
                                  </span>
                                  <strong
                                    style={{
                                      fontSize:
                                        widget.aqiData.length === 1
                                          ? individualHeight > individualWidth
                                            ? individualWidth * 0.04 + "px"
                                            : individualHeight * 0.03 + "px"
                                          : widget.aqiData.length === 2
                                          ? individualWidth * 0.04 + "px"
                                          : individualWidth * 0.05 + "px",
                                    }}
                                  >
                                    {temperature.toFixed()} °C
                                  </strong>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Landscape;
