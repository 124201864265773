import React, { PureComponent } from "react";

import Landscape from "./components/Landscape";
import Portrait from "./components/Portrait";
import Footer from "./components/Footer";
import { getDisplayType } from "../../../utils/helper";

export default class ClockWidget extends PureComponent {
  state = {
    date: new Date(),
    fontSize: this.props.widget?.data?.fontSize || 0,
    fitContent: false,
    displayType: "landscape",
    height: window.innerHeight,
    width: window.innerWidth,
  };

  componentDidMount = () => {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const displayType = getDisplayType(width, height);
    this.setState({ displayType });
    const doc = document.querySelector("#doc");
    if (doc.scrollHeight > doc.clientHeight) {
      this.setState({ height: doc.clientHeight });
    }
    if (doc.scrollWidth > doc.clientWidth) {
      this.setState({ width: doc.clientWidth });
    }
    document.documentElement.style.overflow = "hidden";
    document.documentElement.style.position = "relative";
    document.querySelector("html").style.overflow = "hidden";
    document.querySelector("body").style.overflow = "hidden";
    // listen to resize
    window.addEventListener("resize", () => window.location.reload());

    var windowWidth = window.innerWidth;
    this.setState({
      fontSize: windowWidth / 8,
    });
  };

  render() {
    const { fontSize, displayType, height, width } = this.state;
    const widget = this.props.widget;

    return (
      <div
        id="doc"
        className="m-0 p-0"
        style={{
          zoom: "0",
          fontWeight: widget.data?.fontBold ? "900" : "normal",
        }}
      >
        {displayType === "landscape" && (
          <Landscape
            height={height}
            width={width}
            fontSize={fontSize}
            widget={this.props.widget}
          />
        )}
        {displayType === "portrait" && (
          <Portrait
            height={height}
            width={width}
            fontSize={fontSize}
            widget={this.props.widget}
          />
        )}
        {displayType === "footer" && (
          <Footer
            height={height}
            width={width}
            fontSize={fontSize}
            widget={this.props.widget}
          />
        )}
      </div>
    );
  }
}

export const ANALOG_CLOCKS = [
  "/images/clock/black-clock.svg",
  "/images/clock/white-clock.svg",
  "/images/clock/face3.svg",
];
