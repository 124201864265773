import React from "react";
import { QRCodeSVG } from "qrcode.react";

const Landscape = ({ widget }) => {
  const createWiFiQRText = () => {
    return `WIFI:T:${widget.data.encryption};S:${widget.data.ssid};P:${widget.data.password};;`;
  };
  return (
    <div
      className="h-100 d-flex flex-row justify-content-center align-items-center p-4 gap-3"
      style={{
        background: "#51B3F6",
      }}
    >
      <img
        src="/images/wifi/wifi-signals.png"
        alt=""
        style={{
          transform: "rotate(270deg)",
          height: "20%",
        }}
      />
      <div
        className="card"
        style={{
          background: "#1475CC",
          height: "80%",
          width: "40%",
          borderRadius: "25px",
          marginRight: "10px",
        }}
      >
        <div className="card-body">
          <div className="d-flex justify-content-between h-100 flex-column">
            <div
              className="w-100 p-2"
              style={{
                background: widget.data.bgColor || "#fff",
                borderRadius: "25px",
              }}
            >
              <QRCodeSVG
                bgColor="transparent"
                fgColor={widget.data.qrColor}
                level="Q"
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "10px",
                }}
                value={createWiFiQRText()}
              />
            </div>
            <h3 className="text-center text-white">SCAN TO CONNECT</h3>
          </div>
        </div>
      </div>

      <div
        className="card"
        style={{
          background: "#1475CC",
          height: "80%",
          width: "40%",
          borderRadius: "25px",
        }}
      >
        <div className="card-body">
          <div className="d-flex justify-content-center h-100 flex-column">
            <h4 className="text-center text-white">{widget.data.ssid}</h4>
            <h4 className="text-center text-white">{widget.data.encryption}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landscape;
