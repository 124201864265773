import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import Package from "../package.json";
import reportWebVitals from "./reportWebVitals";
import "./css/main.css";
import "core-js/actual";
import "regenerator-runtime/runtime";
import axios from "axios";
import { server } from "./env";

ReactDOM.render(<App />, document.getElementById("root"));

var currentVersion = parseFloat(localStorage.getItem("app_version") || 1.0);

// if (incomingVersion !== currentVersion) {
//   serviceWorkerRegistration.unregister();
//   localStorage.setItem("app_version", Package.version);
//   window.location.reload();
// } else {
serviceWorkerRegistration.register();
// }

axios
  .get(`${server}/v3/cms/widget/getVersion`)
  .then((res) => {
    var incomingVersion = parseFloat(res.data || 1.0);
    console.log(`Local -  v${currentVersion}, Incoming - v${incomingVersion}`);

    if (incomingVersion > currentVersion) {
      localStorage.setItem("app_version", res.data);
      try {
        serviceWorkerRegistration.unregister();

        try {
          if ("serviceWorker" in navigator) {
            navigator.serviceWorker
              .getRegistrations()
              .then(function (registrations) {
                for (let registration of registrations) {
                  registration.unregister();
                }
              })
              .catch(function (err) {
                console.error("Service worker unregister failed: ", err);
              });
          }
        } catch (e) {
          console.log(e);
        }

        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    }
  })
  .catch((err) => {
    console.log(err);
  });

reportWebVitals();
