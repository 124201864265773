import React, { PureComponent } from "react";
import { getIndividualArea } from "../../../../utils/helper";
import { imgPath, gradientColors } from "./imgData";

class Footer extends PureComponent {
  componentDidMount = () => {};

  render() {
    const { widget } = this.props;
    const height = window.innerHeight;
    const width = window.innerWidth;

    const isLandscape = window.innerHeight < window.innerWidth;
    const { height: individualHeight, width: individualWidth } =
      getIndividualArea(height, width, widget?.aqiData?.length || 1);

    const dayOrNight =
      new Date().getHours() >= 18 || new Date().getHours() <= 6
        ? "night"
        : "day";

    const portraitOrLandscapeImgPath = isLandscape ? "landscape" : "portrait";

    return (
      <div
        style={{
          height: window.innerHeight + "px",
          width: window.innerWidth + "px",
        }}
        className="p-0 m-0"
      >
        <div
          className={
            "d-flex justify-content-center align-items-stretch m-0 p-0 " +
            (window.innerHeight > window.innerWidth
              ? "flex-column"
              : "flex-row")
          }
          style={{
            width: window.innerWidth + "px",
            height: window.innerHeight + "px",
          }}
        >
          {widget.aqiData.map((aqi, index) => {
            const Aqi = aqi;
            const percentage = (Aqi.aqi / 500) * 100;
            const primaryColor = widget.data.secondaryColor
            const secondaryColor = widget.data.secondaryColor
            const todayAqi = Aqi.aqi && Aqi.aqi[0];
            const city = Aqi.city[0];
            let description =
              todayAqi && todayAqi.main && todayAqi.main.toLowerCase();
            let tempIndex = 0;
            if (percentage <= 25) tempIndex = 0;
            if (percentage > 25 && percentage <= 60) tempIndex = 1;
            if (percentage > 60) tempIndex = 2;
            if (["Healthy"].includes(description)) description = "Healthy";
            if (["Unhealthy"].includes(description)) description = "Unhealthy";
            if (["Hazardous"].includes(description)) description = "Hazardous";
            return (
              <div
                className={`card p-0 m-0 border-0 aqi-card aqi ${
                  widget.data.animation && description
                }`}
                style={{
                  // width:
                  //   window.innerHeight > window.innerWidth
                  //     ? window.innerWidth + "px"
                  //     : window.innerWidth / widget.data.city.length + "px",
                  //     height: window.innerHeight + "px",
                  width:
                    window.innerWidth > window.innerHeight
                      ? individualWidth
                      : window.innerWidth,
                  height:
                    window.innerWidth > window.innerHeight
                      ? window.innerHeight
                      : individualHeight,
                  borderRadius: "0px",

                  backgroundImage: gradientColors[tempIndex],
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
                key={index}
              >
                <div className="card-body p-0 h-100" style={{ zIndex: 100 }}>
                  <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                    <div className="w-100 text-center"
                    style={{
                      fontSize: isLandscape
                            ? widget.aqiData.length === 1
                              ? individualHeight * 0.15 + "px"
                              : widget.aqiData.length === 2
                              ? individualHeight * 0.18 + "px"
                              : individualHeight * 1 + "px"
                            : widget.aqiData.length === 1
                            ? individualWidth * 0.15 + "px"
                            : widget.aqiData.length === 2
                            ? individualWidth * 0.18 + "px"
                            : individualWidth * 0.65 + "px",
                    }}>
                    {percentage <= 25
              ? "Healthy"
              : percentage > 25 && percentage <= 60
              ? "Unhealthy"
              : "Hazardous"}
                      <div
                        style={{
                          fontSize: isLandscape
                            ? widget.aqiData.length === 1
                              ? individualHeight * 0.3 + "px"
                              : widget.aqiData.length === 2
                              ? individualHeight * 0.6 + "px"
                              : individualHeight * 1 + "px"
                            : widget.aqiData.length === 1
                            ? individualWidth * 0.3 + "px"
                            : widget.aqiData.length === 2
                            ? individualWidth * 0.65 + "px"
                            : individualWidth * 0.65 + "px",
                          lineHeight: "1",
                          fontWeight: "bold"
                        }}
                        className="text-center"
                      >
                        <span className="">
                        {Aqi.aqi}
                        </span>
                      </div>
                      <div
                        className="text-center"
                        style={{
                          fontSize: isLandscape
                            ? widget.aqiData.length === 1
                              ? individualHeight * 0.16 + "px"
                              : widget.aqiData.length === 2
                              ? individualHeight * 0.2 + "px"
                              : individualHeight * 0.4 + "px"
                            : widget.aqiData.length === 1
                            ? individualWidth * 0.2 + "px"
                            : widget.aqiData.length === 2
                            ? individualWidth * 0.25 + "px"
                            : individualWidth * 0.27 + "px",
                        }}
                      >
                        {/* {description === "clouds" ? (
                          <span>
                            <i className="bi bi-cloud"></i> Clouds
                          </span>
                        ) : description === "clear" ? (
                          <span>
                            <i className="bi bi-brightness-high"></i> Clear
                          </span>
                        ) : description === "rain" ? (
                          <span>
                            <i className="bi bi-cloud-drizzle"></i> Rain
                          </span>
                        ) : description === "haze" ? (
                          <span>
                            <i className="bi bi-cloud-haze2"></i> Haze
                          </span>
                        ) : description === "snow" ? (
                          <span>
                            <i className="bi bi-cloud-snow"></i>
                            Snow
                          </span>
                        ) : description === "thunderstorm" ? (
                          <span>
                            <i className="bi bi-thunderstorm"></i>
                            Thunderstorm
                          </span>
                        ) : (
                          <span>
                            <i className="bi bi-brightness-high"></i>
                            Clear
                          </span>
                        )} */}
                      </div>
                    </div>
                    <div className="text-center">
                      <span
                        className="d-block"
                        style={{
                          fontSize: isLandscape
                            ? widget.aqiData.length === 1
                              ? individualHeight * 0.25 + "px"
                              : widget.aqiData.length === 2
                              ? individualHeight * 0.3 + "px"
                              : individualHeight * 0.55 + "px"
                            : widget.aqiData.length === 1
                            ? individualWidth * 0.16 + "px"
                            : widget.aqiData.length === 2
                            ? individualWidth * 0.25 + "px"
                            : individualWidth * 0.37 + "px",
                            fontWeight: "bold"
                        }}
                      >
                        {Aqi.city.toUpperCase() + city.slice(1)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Footer;
