import React, { PureComponent } from "react";

import Landscape from "./components/Landscape";
import Portrait from "./components/Portrait";
import { getDisplayType } from "../../../utils/helper";

export default class Football extends PureComponent {
  state = {
    displayType: "landscape",
    height: window.innerHeight,
    width: window.innerWidth,
  };

  componentDidMount = () => {
    const { height, width } = this.state;

    const displayType = getDisplayType(width, height);
    this.setState({ displayType: displayType });
  };

  render() {
    const { displayType } = this.state;
    const widget = this.props.widget;
    return (
      <div id="doc" className="text-white">
        {displayType === "landscape" && <Landscape widget={widget} />}
        {displayType === "portrait" && <Portrait widget={widget} />}
      </div>
    );
  }
}
