import React, { PureComponent } from "react";
import { getIndividualArea } from "../../../../utils/helper";
import { imgPath } from "./imgData";
const gradientColors = [
  "linear-gradient(to right, #000, #46403f)", // <5
  "linear-gradient(to right, #00f1ff, #612dfd)", //5-10
  "linear-gradient(to right, #5522c3, #2d96fd)", //15-25
  "linear-gradient(to right, #5522c3, #2d96fd)", //15-25
  "linear-gradient(to right, #005ff5, #00a9f5)", //10-15
  "linear-gradient(to right, #005ff5, #00a9f5)", //10-15
  "linear-gradient(to right, #ff512f, #ffb02f)", // 25-35
  "linear-gradient(to right, #ff512f, #ffb02f)", // 30 - 35
  "linear-gradient(to right, #ff416c, #ff4b2b)", // 35-40
];

class Portrait extends PureComponent {
  componentDidMount = () => {
    const big = window.innerWidth / window.innerHeight > 0.65;
    if (big && this.props.widget.weather.length === 1) {
      this.props.setDisplayType("landscape");
    }
    if (!big) {
      this.props.setDisplayType("footer");
    }
  };

  render() {
    const { widget } = this.props;
    const height = window.innerHeight;
    const { height: individualHeight, width: individualWidth } =
      getIndividualArea(height, window.innerWidth, widget.weather.length);
    const dayOrNight =
      new Date().getHours() >= 18 || new Date().getHours() <= 6
        ? "night"
        : "day";

    const portraitOrLandscapeImgPath =
      individualHeight < window.innerWidth ? "landscape" : "portrait";

    const isForecast = !!widget?.data?.isForecast;

    return (
      <div
        style={{
          height: window.innerHeight + "px",
          width: window.innerWidth + "px",
        }}
        className=""
      >
        <div
          className="d-flex flex-column justify-content-center align-items-stretch"
          style={{
            height: window.innerHeight + "px",
            width: window.innerWidth + "px",
          }}
        >
          {widget.weather.map((weather, index) => {
            const todayData = weather.todayData;
            const city = weather.city;
            const forecast = weather.forecast;
            const todayWeather =
              todayData && todayData.weather && todayData.weather[0];
            let description =
              todayWeather &&
              todayWeather.main &&
              todayWeather.main.toLowerCase();

            const temp = todayData && todayData.main && todayData.main.temp;
            let tempIndex = 0;
            if (temp > 5 && temp <= 10) tempIndex = 1;
            else if (temp > 10 && temp <= 15) tempIndex = 2;
            else if (temp > 15 && temp <= 18) tempIndex = 3;
            else if (temp > 18 && temp <= 22) tempIndex = 4;
            else if (temp > 22 && temp <= 25) tempIndex = 5;
            else if (temp > 25 && temp <= 30) tempIndex = 6;
            else if (temp > 30 && temp <= 35) tempIndex = 7;
            else if (temp > 35) tempIndex = 8;

            if (["thunderstorm", "drizzle", "rain"].includes(description))
              description = "rain";
            else if (
              [
                "mist",
                "smoke",
                "haze",
                "dust",
                "fog",
                "sand",
                "dust",
                "ash",
                "squall",
                "tornado",
              ].includes(description)
            )
              description = "clear";
            return (
              <div
                className={`card h-100 border-0 weather-card weather ${
                  widget.data.animation && description
                }`}
                style={{
                  // width: window.innerWidth + "px",
                  // fontSize: height * 0.15 + "px",
                  borderRadius: "0px",
                  backgroundImage: widget.data.bgImage
                    ? `linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.498)), url(${imgPath[portraitOrLandscapeImgPath][description][dayOrNight]})`
                    : gradientColors[tempIndex],
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
                key={index}
              >
                <div className="card-body py-1 h-100" style={{ zIndex: 100 }}>
                  <div
                    className={
                      "d-flex w-100 h-100 justify-content-center align-items-center"
                    }
                  >
                    {/* Temperature */}
                    <div className="d-flex flex-column h-100 justify-content-center align-items-center w-50 text-white">
                      <div
                        style={{
                          fontSize:
                            widget.weather.length === 1
                              ? individualWidth * 0.15 + "px"
                              : widget.weather.length === 2
                              ? individualWidth * 0.2 + "px"
                              : individualWidth * 0.2 + "px",
                          lineHeight: "1",
                        }}
                      >
                        {description === "clouds" ? (
                          <i className="bi bi-cloud"></i>
                        ) : description === "clear" ? (
                          <i className="bi bi-brightness-high"></i>
                        ) : description === "rain" ? (
                          <i className="bi bi-cloud-drizzle"></i>
                        ) : description === "haze" ? (
                          <i className="bi bi-cloud-haze2"></i>
                        ) : description === "snow" ? (
                          <i className="bi bi-cloud-snow"></i>
                        ) : description === "thunderstorm" ? (
                          <i className="bi bi-thunderstorm"></i>
                        ) : (
                          <i className="bi bi-brightness-high"></i>
                        )}
                      </div>
                      <div
                        style={{
                          fontSize:
                            widget.weather.length === 1
                              ? individualWidth * 0.15 + "px"
                              : widget.weather.length === 2
                              ? individualWidth * 0.25 + "px"
                              : individualWidth * 0.3 + "px",
                        }}
                      >
                        {todayData.main.temp.toFixed(0)}
                        °C
                      </div>
                      <div
                        style={{
                          fontSize:
                            widget.weather.length === 1
                              ? individualWidth * 0.1 + "px"
                              : widget.weather.length === 2
                              ? individualWidth * 0.12 + "px"
                              : individualWidth * 0.1 + "px",
                        }}
                      >
                        {city.name}
                      </div>
                    </div>

                    {/* Stats */}
                    {!isForecast ? (
                      <div
                        style={{
                          color:
                            todayData.main.temp > 35 ? "#ff7e5f" : "#3a6073",
                        }}
                        className="d-flex w-75 h-100 flex-column justify-content-center align-items-center"
                      >
                        {/* Wind & Clouds */}
                        <div
                          className="card mb-1 w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.weather.length === 1
                                ? individualWidth * 0.04 + "px"
                                : widget.weather.length === 2
                                ? individualWidth * 0.06 + "px"
                                : individualWidth * 0.09 + "px",
                          }}
                        >
                          <div className="card-body px-0 py-2">
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                                <span>
                                  {description === "clouds" ? (
                                    <i className="bi bi-cloud"></i>
                                  ) : description === "clear" ? (
                                    <i className="bi bi-brightness-high"></i>
                                  ) : description === "rain" ? (
                                    <i className="bi bi-cloud-drizzle"></i>
                                  ) : description === "haze" ? (
                                    <i className="bi bi-cloud-haze2"></i>
                                  ) : description === "snow" ? (
                                    <i className="bi bi-cloud-snow"></i>
                                  ) : description === "thunderstorm" ? (
                                    <i className="bi bi-thunderstorm"></i>
                                  ) : (
                                    <i className="bi bi-brightness-high"></i>
                                  )}
                                </span>

                                <span>{description.toUpperCase()}</span>
                              </div>
                              <div className="border"></div>
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                                <span>
                                  <i className="bi bi-wind"></i>
                                </span>
                                <span>{todayData.wind.speed}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* humidity and air pressure */}
                        <div
                          className="card mb-1 w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.weather.length === 1
                                ? individualWidth * 0.04 + "px"
                                : widget.weather.length === 2
                                ? individualWidth * 0.06 + "px"
                                : individualWidth * 0.09 + "px",
                          }}
                        >
                          <div className="card-body px-0 py-2">
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                                <span>
                                  <i className="bi bi-droplet"></i>
                                </span>
                                <span className="">
                                  {todayData.main.humidity}
                                </span>{" "}
                              </div>
                              <div className="border"></div>
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                                <span>
                                  <i className="bi bi-speedometer2"></i>
                                </span>
                                <span>{todayData.main.pressure}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* sun rise and sun set */}
                        <div
                          className="card w-100"
                          style={{
                            borderRadius: "22px",
                            fontSize:
                              widget.weather.length === 1
                                ? individualWidth * 0.04 + "px"
                                : widget.weather.length === 2
                                ? individualWidth * 0.06 + "px"
                                : individualWidth * 0.09 + "px",
                          }}
                        >
                          <div className="card-body px-0 py-2">
                            <div className="d-flex w-100 justify-content-around">
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                                <span>
                                  <i className="bi bi-sunrise"></i>
                                </span>
                                <span>{city.sunrise || ""}</span>
                              </div>
                              <div className="border"></div>
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ width: "33%" }}
                              >
                                <span>
                                  <i className="bi bi-sunset"></i>
                                </span>
                                <span>{city.sunset || ""}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          widget.weather.length === 1 ? "w-50" : "w-75"
                        }
                        style={{
                          color:
                            todayData.main.temp > 35 ? "#ff7e5f" : "#23282c",
                          background: "rgba(255, 255, 255, 1)",
                          borderRadius: individualWidth * 0.02 + "px",
                        }}
                      >
                        <div
                          className="card-body"
                          style={{
                            padding:
                              widget.weather.length === 1
                                ? individualWidth * 0.05 + "px"
                                : individualWidth * 0.02 + "px",
                          }}
                        >
                          {/* today data */}
                          <div
                            style={{
                              fontSize:
                                widget.weather.length === 1
                                  ? individualWidth * 0.04 + "px"
                                  : individualWidth * 0.09 + "px",
                              gap: individualWidth * 0.04 + "px",
                              fontWeight: widget.data.fontBold
                                ? "bolder"
                                : "normal",
                            }}
                          >
                            <div className="d-flex">
                              <div
                                className="d-flex justify-content-center w-100"
                                style={{ marginLeft: "auto" }}
                              >
                                <span>
                                  {description === "clouds" ? (
                                    <i className="bi bi-cloud"></i>
                                  ) : description === "clear" ? (
                                    <i className="bi bi-brightness-high"></i>
                                  ) : description === "rain" ? (
                                    <i className="bi bi-cloud-drizzle"></i>
                                  ) : description === "haze" ? (
                                    <i className="bi bi-cloud-haze2"></i>
                                  ) : description === "snow" ? (
                                    <i className="bi bi-cloud-snow"></i>
                                  ) : description === "thunderstorm" ? (
                                    <i className="bi bi-thunderstorm"></i>
                                  ) : (
                                    <i className="bi bi-brightness-high"></i>
                                  )}
                                </span>

                                <span
                                  className="text-capitalize"
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {description.toLowerCase()}
                                </span>
                              </div>
                              <div className="d-flex justify-content-center w-100">
                                <span className="">
                                  <i className="bi bi-wind"></i>
                                </span>
                                <span
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {todayData.wind.speed}
                                </span>
                              </div>
                            </div>

                            <div className="d-flex">
                              <div className="d-flex justify-content-center w-100">
                                <span className="">
                                  <i className="bi bi-sunrise"></i>
                                </span>
                                <span
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {city.sunrise || ""}
                                </span>
                              </div>
                              <div className="d-flex justify-content-center w-100">
                                <span className="">
                                  <i className="bi bi-sunset"></i>
                                </span>
                                <span
                                  style={{
                                    marginLeft: individualWidth * 0.01 + "px",
                                  }}
                                >
                                  {city.sunset || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* Forecast */}
                          <div
                            className="d-flex justify-content-around"
                            style={{ marginTop: individualWidth * 0.02 + "px" }}
                          >
                            {forecast.map((data, index) => {
                              const description =
                                data.weather &&
                                data.weather[0].main &&
                                data.weather[0].main.toLowerCase();
                              const day = new Date(
                                data.dt * 1000
                              ).toLocaleDateString("en-US", {
                                weekday: "short",
                              });
                              const temperature = data.main.temp;
                              return (
                                <div
                                  className="d-flex flex-column justify-content-center align-items-center"
                                  key={index}
                                  style={{
                                    background: "#b6bbc7",
                                    borderRadius: individualWidth * 0.02 + "px",
                                    padding: individualWidth * 0.01 + "px",
                                    width: "30%",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize:
                                        widget.weather.length === 1
                                          ? individualWidth * 0.03 + "px"
                                          : individualWidth * 0.1 + "px",
                                    }}
                                  >
                                    {description === "clouds" ? (
                                      <i className="bi bi-cloud"></i>
                                    ) : description === "clear" ? (
                                      <i className="bi bi-brightness-high"></i>
                                    ) : description === "rain" ? (
                                      <i className="bi bi-cloud-drizzle"></i>
                                    ) : description === "haze" ? (
                                      <i className="bi bi-cloud-haze2"></i>
                                    ) : description === "snow" ? (
                                      <i className="bi bi-cloud-snow"></i>
                                    ) : description === "thunderstorm" ? (
                                      <i className="bi bi-thunderstorm"></i>
                                    ) : (
                                      <i className="bi bi-brightness-high"></i>
                                    )}
                                  </span>
                                  <span
                                    style={{
                                      fontSize:
                                        widget.weather.length === 1
                                          ? individualWidth * 0.03 + "px"
                                          : individualWidth * 0.08 + "px",
                                    }}
                                  >
                                    {day}
                                  </span>
                                  <span
                                    style={{
                                      fontSize:
                                        widget.weather.length === 1
                                          ? individualWidth * 0.03 + "px"
                                          : widget.weather.length === 2
                                          ? individualWidth * 0.08 + "px"
                                          : individualWidth * 0.1 + "px",
                                      lineHeight: 1.8,
                                    }}
                                  >
                                    {temperature.toFixed()} °C
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Portrait;
