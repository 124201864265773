import React, { useState, useEffect } from "react";
import axios from "axios";

import { footballServer } from "../../../../env";
import { getFlag, clubFlags } from "../helper";

// random flag if no flag available
const random1 = Math.round(Math.random() * clubFlags.length);
const random2 = Math.round(Math.random() * clubFlags.length);

const Landscape = ({ widget }) => {
  const [matchData, setMatchData] = useState({});
  const [fontSize, setFontSize] = useState(0);

  useEffect(() => {
    getMatchData();

    // set interval to fetch data every 5 seconds
    const interval = setInterval(() => {
      getMatchData();
    }, 5000);

    // clear interval on unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    var windowWidth = window.innerWidth;

    setFontSize(windowWidth / 8);
    setInterval(() => {
      for (var i = 0; i < 100; i++) {
        // if window has scrollbar, then reduce font-size
        if (document.getElementById("doc").offsetHeight > window.innerHeight) {
          setFontSize(fontSize - 10);
        } else {
          if (
            document.getElementById("doc").offsetHeight < window.innerHeight
          ) {
            setFontSize(fontSize + 10);
          }
        }
      }
    }, 1000);
  }, []);

  const getMatchData = () => {
    const tourId = widget?.data?.tourId || "";
    const gameId = widget?.data?.gameId || "";
    axios
      .get(`${footballServer}/api/read?tourId=${tourId}&gameId=${gameId}`)
      .then((res) => {
        setMatchData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let team1Flag = getFlag(matchData?.team1ShortName);
  let team2Flag = getFlag(matchData?.team2ShortName);

  if (team1Flag.includes("undefined")) {
    const hasFlag = clubFlags.find((flag) =>
      flag.toLowerCase().includes(matchData?.team1?.toLowerCase())
    );
    if (hasFlag) team1Flag = "/assets/club/" + hasFlag;
  }
  if (team2Flag.includes("undefined")) {
    const hasFlag = clubFlags.find((flag) =>
      flag.toLowerCase().includes(matchData?.team2?.toLowerCase())
    );
    if (hasFlag) team2Flag = "/assets/club/" + hasFlag;
  }

  return (
    <div
      className=" d-flex flex-column justify-content-around"
      id="doc"
      style={{
        backgroundColor: "#8b0000",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column">
          <h1
            className="text-center mb-2 fw-bold"
            style={{
              fontSize: fontSize / 3,
            }}
          >
            {matchData.tour}
          </h1>
          <h4
            className="text-center"
            style={{
              fontSize: fontSize / 6,
            }}
          >
            {matchData.venue}
          </h4>
          <h4
            className="text-center"
            style={{
              fontSize: fontSize / 6,
            }}
          >
            Group A
          </h4>
        </div>
      </div>
      <div
        className="d-flex align-items-center"
        style={{
          justifyContent: "space-around",
        }}
      >
        <div className="d-flex flex-column">
          <img
            src={team1Flag}
            alt=""
            className="img-fluid border-white border border-3 rounded-md"
            style={{
              width: fontSize / 1.1,
            }}
          />
          <span className="text-center fs-1">{matchData.team1}</span>
        </div>
        <h1
          className="fw-bold"
          style={{
            fontSize: fontSize / 1.1,
          }}
        >
          {matchData.team1Score}
        </h1>
        <span
          className="fw-bold"
          style={{
            fontSize: fontSize / 1.1,
          }}
        >
          -
        </span>
        <h1
          className="fw-bold"
          style={{
            fontSize: fontSize / 1.1,
          }}
        >
          {matchData.team2Score}
        </h1>
        <div className="d-flex flex-column">
          <img
            src={team2Flag}
            alt=""
            className="img-fluid border-white border border-3 rounded-md"
            style={{
              width: fontSize,
            }}
          />
          <span className="text-center fs-1">{matchData.team2}</span>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column align-items-center">
          <img
            src="/images/football.png"
            alt=""
            className="img-fluid"
            style={{
              height: fontSize / 2,
            }}
          />
          <span>
            <h1
              className="text-center pt-4"
              style={{
                fontSize: fontSize / 3,
              }}
            >
              {matchData.status}
            </h1>
            <h1
              className="text-center w-100"
              style={{
                fontSize: fontSize / 4,
              }}
            >
              {matchData.substatus}
            </h1>
          </span>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
        }}
      >
        <img
          src="/images/White.svg"
          alt=""
          className=""
          style={{
            height: fontSize / 3,
          }}
        />
      </div>
    </div>
  );
};

export default Landscape;
