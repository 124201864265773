import Landscape from "./components/Landscape";

const Index = ({ widget }) => {
  return (
    <>
      <Landscape widget={widget} />
    </>
  );
};

export default Index;
