import { CRYPTOS } from "../cryptoData";

const Crypto = ({
  name,
  color,
  logo,
  code,
  timeIntervalData,
  bgColor,
  fontFamily,
  currency,
}) => {
  const width = window.innerWidth;
  const first = timeIntervalData && timeIntervalData[0];
  const second = timeIntervalData && timeIntervalData[1];
  const openFiveMinLater = (first && first[1]["1. open"]) || 0;
  const openFiveMinOlder = (second && second[1]["1. open"]) || 0;
  const percentage =
    ((Number(openFiveMinLater) - Number(openFiveMinOlder)) /
      Number(openFiveMinOlder)) *
      100 || 0;
  return (
    <div
      className="px-2 py-0 mb-1"
      style={{
        background: bgColor || `linear-gradient(to right, #8555c9, #c488e0)`,
        height: window.innerHeight / 3,
        fontFamily: fontFamily,
      }}
    >
      <p
        style={{
          fontWeight: "600",
          fontSize: width / 12 + "px",
          margin: 0,
          padding: 0,
          color: color,
        }}
      >
        {code || ""}
      </p>
      <p
        className="text-white"
        style={{ fontSize: width / 20 + "px", margin: 0, padding: 0 }}
      >
        {name || ""}
      </p>
      <div className="row" style={{ padding: 0 }}>
        <div className="col-6">
          <img
            className="mt-2"
            src={`/images/crypto/${logo}`}
            alt="logo"
            style={{
              height: width / 9 + "px",
              width: width / 9 + "px",
              margin: 0,
              padding: 0,
            }}
          />
        </div>
        <div className="col-6 align-self-end">
          <div>
            {+percentage >= 0 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width / 18}
                height={width / 18}
                fill="#90ee90"
                className="bi bi-caret-up-fill"
                viewBox="0 0 16 16"
              >
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width / 18}
                height={width / 18}
                fill="red"
                className="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            )}
            <span
              className="align-middle"
              style={{
                fontSize: width / 20 + "px",
                marginLeft: "10px",
              }}
            >
              {percentage?.toFixed(2) || "Loading..."}%
            </span>
          </div>
          <div className="">
            <p
              style={{
                fontWeight: "600",
                fontSize: width / 18 + "px",
              }}
            >
              {openFiveMinLater?.slice(0, -3)} {currency}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Portrait = ({ cryptosData = [], currency = "" }) => {
  return (
    <div>
      {cryptosData?.map((crypto) => {
        console.log(crypto?.timeIntervalData);
        const code = crypto?.code;
        const bgColor = crypto?.bgColor;
        const fontFamily = crypto?.fontFamily;
        const timeIntervalData = crypto?.timeIntervalData;

        const selectedCrypto = CRYPTOS.find((c) => c.code === code);
        const { name, color, logo } = selectedCrypto;

        return (
          <Crypto
            key={code}
            name={name}
            color={color}
            logo={logo}
            code={code}
            bgColor={bgColor}
            fontFamily={fontFamily}
            timeIntervalData={timeIntervalData}
            currency={currency}
          />
        );
      })}
    </div>
  );
};

export default Portrait;
