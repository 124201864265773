import React, { Fragment } from "react";

const Face1 = ({ widget, feed }) => {
  return (
    <Fragment>
      <div
        style={{
          color: widget.data.color,
          backgroundImage: widget.data.bgColor,
          fontFamily: widget.data.fontFamily,
          height: "100%",
          width: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div>
          <img
            className="w-100"
            src={feed.enclosure?._attributes?.url}
            alt=""
            style={{
              position: "absolute",
              filter: "blur(3px)",

              // opacity: 0.7,
              height: window.innerHeight + "px",
            }}
          />

          <div
            style={{
              margin: "3px",
              padding: "10px",
              position: "absolute",
              height: window.innerHeight + "px",
              width: "100%",
              overflow: "hidden",
            }}
            className="d-flex align-items-center "
          >
            <div>
              <div
                className="w-50"
                style={{
                  height: "20px",
                  background: widget.data.color,
                }}
              ></div>

              <span
                style={{
                  textDecoration: "none",
                  color: widget.data.color,
                }}
              >
                <span
                  className="mb-3 d-block"
                  style={{
                    fontSize: (window.innerWidth + window.innerHeight) / 50,
                    fontWeight: "bold",
                    lineHeight: 1,
                  }}
                >
                  {feed.title}
                </span>

                <span
                  className="mb-3 d-block"
                  style={{
                    fontSize: (window.innerWidth + window.innerHeight) / 50,
                    fontWeight: "bold",
                    lineHeight: 1,
                  }}
                >
                  {feed.content}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Face1;
