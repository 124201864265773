const Landscape = () => {
  const width = window.innerWidth;
  return (
    <div style={{ height: "100vh", background: "#3c9ffd" }}>
      <div className="p-4 py-5 h-100">
        <div className="row align-items-center" style={{ height: "40%" }}>
          <div className="col text-center">
            <div>
              <p style={{ fontSize: width / 70 }}>IND</p>
              <p style={{ fontWeight: 800, fontSize: width / 45 }}>180-3</p>
              <p style={{ fontSize: width / 80 }}>20.0 over | RR: 9.0</p>
            </div>
          </div>
          <div className="col text-center">
            <img
              src="/images/cricket/India.png"
              alt="india"
              style={{
                height: width / 10 + "px",
                width: width / 10 + "px",
              }}
            />
          </div>
          <div className="col text-center d-flex align-items-center justify-content-center">
            <p
              className="my-auto d-flex align-items-center justify-content-center"
              style={{
                height: width / 35 + "px",
                width: width / 35 + "px",
                borderRadius: "50%",
                background: "blue",
                fontWeight: 700, // dynamic
                fontSize: width / 60 + "px",
              }}
            >
              vs
            </p>
          </div>
          <div className="col text-center">
            <img
              className="w-50 h-50"
              src="/images/cricket/Australia.png"
              alt="india"
              style={{
                height: width / 10 + "px",
                width: width / 10 + "px",
              }}
            />
          </div>
          <div className="col text-center">
            <div>
              <p style={{ fontSize: width / 70 }}>AUS</p>
              <p style={{ fontWeight: 800, fontSize: width / 45 }}>60-3</p>
              <p style={{ fontSize: width / 80 }}>10.0 over | RR: 6.0</p>
            </div>
          </div>
        </div>

        <div className="row align-items-center" style={{ height: "10%" }}>
          <p className="text-center" style={{ fontSize: width / 70 + "px" }}>
            Match 1, Eden Garden
          </p>
        </div>

        <div className="row align-items-center" style={{ height: "10%" }}>
          <p
            className="text-center"
            style={{
              fontSize: width / 45 + "px",
              fontWeight: 700,
            }}
          >
            Australia needs 120 runs from 60 balls to win
          </p>
        </div>

        <div className="row align-items-center" style={{ height: "40%" }}>
          <div className="col-8">
            <div className="d-flex">
              <div
                className="d-flex  align-items-center"
                style={{ marginRight: "10%" }}
              >
                <img
                  className="w-75 h-75"
                  src="/images/cricket/bat.png"
                  alt="bat"
                />
                <div>
                  <p style={{ fontSize: width / 70 + "px", fontWeight: 700 }}>
                    CUMMINS
                  </p>
                  <p
                    className="not-out"
                    style={{ fontSize: width / 85 + "px" }}
                  >
                    25 (10)
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <img
                  className="w-75 h-75"
                  src="/images/cricket/bat.png"
                  alt="bat"
                />
                <div>
                  <p style={{ fontSize: width / 70 + "px", fontWeight: 700 }}>
                    MAXWELL
                  </p>
                  <p style={{ fontSize: width / 85 + "px" }}>10 (5)</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex justify-content-center">
              <img
                className="mx-4"
                src="/images/cricket/ball.png"
                alt="ball"
                style={{ height: width / 20 + "px", width: width / 20 + "px" }}
              />
              <div className="align-self-center">
                <p style={{ fontSize: width / 70 + "px", fontWeight: 700 }}>
                  JADEJA
                </p>
                <p style={{ fontSize: width / 85 + "px" }}>1/29 (3.5)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landscape;
