import React, { Fragment, PureComponent } from "react";

export default class Ticker extends PureComponent {
  state = {
    date: new Date(),
  };

  componentDidMount = () => {
    // set body background color
    if (this.props.widget?.data?.bgColor?.includes("linear-gradient")) {
      document.body.style.background = this.props.widget?.data?.bgColor;
    } else
      document.body.style.backgroundColor = this.props.widget?.data?.bgColor;
  };

  render() {
    const { widget } = this.props;
    const height = window.innerHeight - (window.innerHeight * 12.5) / 100;
    return (
      <Fragment>
        <div
          style={{
            height: window.innerHeight + "px",
          }}
        >
          <marquee
            scrollamount={widget.data.speed}
            direction={widget.data.direction}
            style={{
              height: window.innerHeight + "px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: height + "px",
                width: "100%",
                fontFamily: widget.data.fontFamily || "sans-serif",
              }}
            >
              <div
                style={{
                  fontSize: height + "px",
                  color: widget.data.color,
                  fontWeight: widget.data.isBold ? "bold" : "normal",
                  fontStyle: widget.data.isItalic ? "italic" : "none",
                }}
              >
                {widget.data.text}
              </div>
            </div>
          </marquee>
        </div>
      </Fragment>
    );
  }
}
