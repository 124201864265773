import { CRYPTOS } from "../cryptoData";

const Crypto = ({
  name,
  color,
  logo,
  code,
  timeIntervalData,
  bgColor,
  fontFamily,
  currency,
}) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const first = timeIntervalData && timeIntervalData[0];
  const second = timeIntervalData && timeIntervalData[1];
  const openFiveMinLater = (first && first[1]["1. open"]) || 0;
  const openFiveMinOlder = (second && second[1]["1. open"]) || 0;
  const percentage =
    ((Number(openFiveMinLater) - Number(openFiveMinOlder)) /
      Number(openFiveMinOlder)) *
      100 || 0;
  return (
    <div
      className="p-4 mb-2"
      style={{
        background: bgColor || `linear-gradient(to right, #8555c9, #c488e0)`,
        width: width / 3 + "px",
        height: height + "px",
        fontFamily: fontFamily,
      }}
    >
      <p
        style={{ fontWeight: "600", fontSize: height / 7 + "px", color: color }}
      >
        {code || ""}
      </p>
      <p className="text-white" style={{ fontSize: height / 10 + "px" }}>
        {name || ""}
      </p>
      <div className="row">
        <div className="col-sm-6">
          <img
            className="mt-5"
            src={`/images/crypto/${logo}`}
            alt="logo"
            style={{ height: height / 5, width: height / 5 }}
          />
        </div>
        <div className="col-sm-6 align-self-end">
          <div>
            {+percentage >= 0 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={height / 10}
                width={height / 10}
                fill="#90ee90"
                className="bi bi-caret-up-fill"
                viewBox="0 0 16 16"
              >
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={height / 10}
                width={height / 10}
                fill="red"
                className="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            )}
            <span
              className="align-middle"
              style={{ fontSize: height / 12 + "px", marginLeft: "10px" }}
            >
              {percentage?.toFixed(2) || "Loading..."}%
            </span>
          </div>
          <div>
            <p style={{ fontWeight: "600", fontSize: height / 10 + "px" }}>
              {openFiveMinLater?.slice(0, -3)} {currency}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = ({ cryptosData = [], currency = "" }) => {
  return (
    <div className="d-flex">
      {cryptosData?.map((crypto) => {
        console.log(crypto?.timeIntervalData);
        const code = crypto?.code;
        const bgColor = crypto?.bgColor;
        const fontFamily = crypto?.fontFamily;
        const timeIntervalData = crypto?.timeIntervalData;

        const selectedCrypto = CRYPTOS.find((c) => c.code === code);
        const { name, color, logo } = selectedCrypto;

        return (
          <Crypto
            key={code}
            name={name}
            color={color}
            logo={logo}
            code={code}
            bgColor={bgColor}
            fontFamily={fontFamily}
            timeIntervalData={timeIntervalData}
            currency={currency}
          />
        );
      })}
    </div>
  );
};

export default Footer;
