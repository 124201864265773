import React, { useState, useEffect, Fragment } from "react";

const Zee = ({ widget }) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const fontFamily = widget.data?.fontFamily;
  const bgColor = widget.data?.bgColor;
  const color = widget.data?.color;
  // const logoUrl = widget.data?.logoUrl || "/images/zee.avg";
  const rssFeed = widget.data?.feeds;

  const [feed, setFeed] = useState(rssFeed.length > 0 ? rssFeed[0] : {});

  useEffect(() => {
    var i = 0;
    setInterval(() => {
      if (i >= rssFeed.length - 1) {
        i = 0;
        setFeed(rssFeed[0]);
      } else {
        setFeed(rssFeed[i + 1]);
        i++;
      }
      console.log(i);
    }, 10000);
  }, []);

  return (
    feed &&
    (window.innerWidth / window.innerHeight > 2.5 ? (
      <div
        style={{
          width: width + "px",
          height: height + "px",
          color,
          fontFamily,
        }}
        className="overflow-hidden d-flex justify-content-center align-items-center"
      >
        <div
          style={{
            color: widget.data.color,
            backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.7),rgba(0,0,0,0.5))`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            backgroundPosition: "center",
            fontFamily: widget.data.fontFamily,
            height: "100vh",
            width: "100vw",
          }}
          className="row align-items-center"
        >
          <div
            className="d-flex justify-content-center align-items-center col-3"
            style={{
              background: "#e4dede",
              height: "100vh",
              width: "15vw",
              borderTopRightRadius: "20%",
              borderBottomRightRadius: "20%",
            }}
          >
            <img
              src="/images/zee.svg"
              alt="logo"
              style={{
                height: "auto",
                width: "100%",
                padding: "5vh",
              }}
            />
          </div>
          <div className="col-10">
            {widget.data?.footerTicker ? (
              <marquee>
                <p style={{ fontSize: "25vh" }}>
                  {rssFeed.map((feed) => feed.title).join("...## ")}
                </p>
              </marquee>
            ) : (
              <p style={{ fontSize: "12vh", marginLeft: "5vh" }}>
                {feed && feed.title}
              </p>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{
          color: widget.data.color,
          background: widget.data.bgColor,
          fontFamily: widget.data.fontFamily,
          height: "100%",
          width: "100%",
        }}
        className="px-4 d-flex justify-content-center align-items-center h-100 w-100"
      >
        <div
          style={{
            margin: "3px",
            padding: "10px",
          }}
        >
          <div
            style={{
              height: "2vh",
              background: widget.data.color,
              marginBottom: "2vh",
              width: "30vw",
            }}
          ></div>
          <span
            style={{
              textDecoration: "none",
              color: widget.data.color,
            }}
          >
            <span
              className="mb-3 d-block"
              style={{
                fontSize: !widget.data?.showDescription
                  ? (window.innerWidth + window.innerHeight) / 35
                  : (window.innerWidth + window.innerHeight) / 50,
                fontWeight: "bold",
                lineHeight: 1,
              }}
            >
              {feed?.title?.replace("<![CDATA[", "").replace("]]>", "")}
            </span>
            {widget.data?.showDescription && (
              <p
                style={{
                  fontSize: (window.innerWidth + window.innerHeight) / 60,
                }}
                className="text-center"
              >
                {feed.description?.substr(0, 250) + "..."}
              </p>
            )}
          </span>
        </div>

        <div
          className="position-absolute bottom-0"
          style={{ right: 0, margin: width / 80 + "px" }}
        >
          <img
            src="/images/zee.svg"
            alt="logo"
            style={{
              height: "15vh",
              width: "15vh",
            }}
          />
        </div>
      </div>
    ))
  );
};

export default Zee;
