export const imgPath = {
  landscape: {
    clear: {
      day: "/images/weather/landscape/clear.png",
      night: "/images/weather/landscape/clear-night.png",
    },
    clouds: {
      day: "/images/weather/landscape/clouds.png",
      night: "/images/weather/landscape/clouds-night.png",
    },
    rain: {
      day: "/images/weather/landscape/rain.png",
      night: "/images/weather/landscape/rain-night.png",
    },
    snow: {
      day: "/images/weather/landscape/snow.png",
      night: "/images/weather/landscape/snow-night.png",
    },
    sunny: {
      day: "/images/weather/landscape/sunny.png",
      night: "/images/weather/landscape/sunny-night.png",
    },
  },
  portrait: {
    clear: {
      day: "/images/weather/portrait/clear.png",
      night: "/images/weather/portrait/clear-night.png",
    },
    clouds: {
      day: "/images/weather/portrait/clouds.png",
      night: "/images/weather/portrait/clouds-night.png",
    },
    rain: {
      day: "/images/weather/portrait/rain.png",
      night: "/images/weather/portrait/rain-night.png",
    },
    snow: {
      day: "/images/weather/portrait/snow.png",
      night: "/images/weather/portrait/snow-night.png",
    },
    sunny: {
      day: "/images/weather/portrait/sunny.png",
      night: "/images/weather/portrait/sunny.png",
    },
  },
};

export const gradientColors = [
  "linear-gradient(to bottom, #000, #46403f)", // <5
  "linear-gradient(to top, #00f1ff, #612dfd)", //5-10
  "linear-gradient(to bottom, #5522c3, #2d96fd)", //15-25
  "linear-gradient(to bottom, #5522c3, #2d96fd)", //15-25
  "linear-gradient(to bottom, #005ff5, #00a9f5)", //10-15
  "linear-gradient(to bottom, #005ff5, #00a9f5)", //10-15
  "linear-gradient(to bottom, #ff512f, #ffb02f)", // 25-35
  "linear-gradient(to bottom, #ff512f, #ffb02f)", // 30 - 35
  "linear-gradient(to top, #ff416c, #ff4b2b)", // 35-40
];
