import React, { useState, useEffect } from "react";

const Abp = ({ widget }) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const faceId = widget.faceId;
  const fontFamily = widget.data?.fontFamily;
  const bgColor = widget.data?.bgColor;
  const color = widget.data?.color;
  const logoUrl = "/images/abp.svg";
  const rssFeed = widget.data?.feeds; //[{link,title,description,img}]

  const [feed, setFeed] = useState(rssFeed.length > 0 ? rssFeed[0] : {});

  useEffect(() => {
    var i = 0;

    const interval = setInterval(() => {
      if (i >= rssFeed.length - 1) {
        i = 0;
        setFeed(rssFeed[0]);
      } else {
        setFeed(rssFeed[i + 1]);
        i++;
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return window.innerWidth / window.innerHeight > 2.5 ? (
    <div
      style={{ width: width + "px", height: height + "px", color, fontFamily }}
      className="overflow-hidden d-flex justify-content-center align-items-center"
    >
      <div
        style={{
          color: widget.data.color,
          backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.7),rgba(0,0,0,0.5))`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "center",
          fontFamily: widget.data.fontFamily,
          height: "100vh",
          width: "100%",
        }}
        className="row align-items-center"
      >
        <div
          className="d-flex justify-content-center align-items-center col-3"
          style={{
            background: "#e4dede",
            height: "100vh",
            width: "15vw",
            borderTopRightRadius: "20%",
            borderBottomRightRadius: "20%",
          }}
        >
          <img
            src={logoUrl}
            alt="logo"
            style={{
              height: "80vh",
              width: "auto",
              padding: "5vh",
            }}
          />
        </div>
        <div className="col-10">
          {widget.data?.footerTicker ? (
            <marquee>
              <p style={{ fontSize: "25vh" }}>
                {rssFeed.map((feed) => feed.title).join("...## ")}
              </p>
            </marquee>
          ) : (
            <p style={{ fontSize: "12vh", marginLeft: "5vh" }}>
              {feed && feed.title}
            </p>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{ width: width + "px", height: height + "px", color, fontFamily }}
      className="overflow-hidden d-flex flex-column justify-content-center align-items-center"
    >
      <div
        style={{
          color: widget.data.color,
          backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.7),rgba(0,0,0,0.7)), url(${feed?.img})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: width + "px " + height + "px",
          fontFamily: widget.data.fontFamily,
          height: "100vh",
          width: "100wh",
        }}
        className="d-flex justify-content-center"
      >
        <div
          style={{
            height: "100vh",
            width: "100vw",
            position: "relative",
          }}
          className="d-flex flex-column justify-content-center align-items-center px-5"
        >
          <div style={{ position: "absolute", top: "0", right: "0" }}>
            {/* <span
              style={{
                fontSize: (window.innerWidth + window.innerHeight) / 80,
              }}
            >
              {feed.pubDate}
            </span> */}
          </div>
          <span
            className="mb-3 d-block text-center"
            style={{
              fontSize: !widget.data?.showDescription
                ? (window.innerWidth + window.innerHeight) / 30
                : (window.innerWidth + window.innerHeight) / 45,

              fontWeight: "bold",
              lineHeight: 1.25,
            }}
            dangerouslySetInnerHTML={{ __html: feed?.title }}
          ></span>
          {/* {widget.data?.showDescription && (
            <p
              style={{
                fontSize: (window.innerWidth + window.innerHeight) / 50,
              }}
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: feed?.content?.substr(0, 200) + "...",
              }}
            ></p>
          )} */}
        </div>
      </div>

      <div
        className="position-absolute bottom-0"
        style={{ right: 0, margin: width / 80 + "px" }}
      >
        <img
          src={logoUrl}
          alt="logo"
          style={{
            height: "18vh",
            width: "18vh",
          }}
        />
      </div>
    </div>
  );
};

export default Abp;
