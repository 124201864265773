import React, { Fragment, PureComponent } from "react";
import Face1 from "./Face1";
import Face2 from "./Face2";
import Face3 from "./Face3";

export default class Index extends PureComponent {
  state = {
    feedItems: [],
    feed: {},
    currentFeed: 0,
  };

  componentDidMount = () => {
    if (this.props.widget) {
      const data = this.props.widget.data;

      const feedItems = data.feeds;
      this.setState({
        feed: feedItems.length > 0 ? feedItems[0] : {},
      });
      this.timer = setInterval(() => {
        this.setState({
          feed:
            this.state.currentFeed + 1 > feedItems.length - 1
              ? feedItems[0]
              : feedItems[this.state.currentFeed + 1],
          currentFeed:
            this.state.currentFeed + 1 > feedItems.length - 1
              ? 0
              : this.state.currentFeed + 1,
        });
      }, 10000);
    }
  };

  render() {
    const { feed } = this.state;
    const widget = this.props.widget;

    return (
      <>
        {(widget?.faceId === "1" || widget.data.faceId === "1") && (
          <Face1 feed={feed} widget={widget} />
        )}

        {(widget?.faceId === "2" || widget.data.faceId === "2") && (
          <Face2 feed={feed} widget={widget} />
        )}

        {(widget?.faceId === "3" || widget.data.faceId === "3") && (
          <Face3 feed={feed} widget={widget} />
        )}
      </>
    );
  }
}
