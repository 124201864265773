import { useState, useEffect } from "react";

import Portrait from "./components/Portrait";
import Landscape from "./components/Landscape";
import Footer from "./components/Footer";
const Index = () => {
  const [displayType, setDisplayType] = useState("landscape");
  const height = window.innerHeight;
  const width = window.innerWidth;

  useEffect(() => {
    if (height > width) setDisplayType("portrait");
    if (width > height && width < height * 2) setDisplayType("landscape");
    if (width >= 4.5 * height) setDisplayType("footer");
  }, [height, width, setDisplayType]);

  return (
    <>
      {displayType === "landscape" && <Landscape />}
      {displayType === "portrait" && <Portrait />}
      {displayType === "footer" && <Footer />}
    </>
  );
};

export default Index;
