const Portrait = () => {
  const height = window.innerHeight;
  return (
    <div
      className="mx-auto px-4 py-3"
      style={{ height: "100vh", background: "#3c9ffd" }}
    >
      <div className="row align-items-center" style={{ height: "60%" }}>
        <div className="col text-center">
          <img
            src="/images/cricket/India.png"
            alt="india"
            style={{
              height: height / 5 + "px",
              width: height / 5 + "px",
            }}
          />
          <p
            className="p-0 mt-3 mb-0 mx-0"
            style={{ fontSize: height / 35 + "px" }}
          >
            IND
          </p>
          <p
            className="p-0 m-0"
            style={{ fontSize: height / 20 + "px", fontWeight: 700 }}
          >
            180-3
          </p>
          <p className="p-0 m-0" style={{ fontSize: height / 35 + "px" }}>
            20.0 over | RR 9.0
          </p>
        </div>

        <div className="col text-center d-flex align-items-center justify-content-center">
          <p
            className="my-auto d-flex align-items-center justify-content-center"
            style={{
              height: height / 25 + "px",
              width: height / 25 + "px",
              borderRadius: "50%",
              background: "blue",
              fontWeight: 700, // dynamic
              fontSize: height / 40 + "px",
            }}
          >
            vs
          </p>
        </div>

        <div className="col text-center">
          <img
            src="/images/cricket/Australia.png"
            alt="australia"
            style={{
              height: height / 5 + "px",
              width: height / 5 + "px",
            }}
          />
          <p
            className="p-0 mt-3 mb-0 mx-0"
            style={{ fontSize: height / 35 + "px" }}
          >
            AUS
          </p>
          <p
            className="p-0 m-0"
            style={{ fontSize: height / 20 + "px", fontWeight: 700 }}
          >
            60-3
          </p>
          <p className="p-0 m-0" style={{ fontSize: height / 35 + "px" }}>
            10.0 over | RR 6.0
          </p>
        </div>
      </div>

      <div className="row text-center" style={{ height: "10%" }}>
        <p style={{ fontSize: height / 35 + "px" }}>Match 1, Eden Garden</p>
      </div>

      <div className="row  align-items-center" style={{ height: "10%" }}>
        <p
          className="text-center"
          style={{
            fontSize: height / 30 + "px",
            fontWeight: 700,
          }}
        >
          Australia needs 120 runs from 60 balls to win
        </p>
      </div>

      <div className="row align-items-center" style={{ height: "20%" }}>
        <div className="col-4">
          <div className="d-flex justify-content-center">
            <img
              style={{ height: height / 10 + "px", width: height / 10 + "px" }}
              className=""
              src="/images/cricket/bat.png"
              alt="bat"
            />
            <div className="align-self-center">
              <p style={{ fontSize: height / 40 + "px", fontWeight: 700 }}>
                CUMMINS
              </p>
              <p className="not-out" style={{ fontSize: height / 50 + "px" }}>
                25(10)
              </p>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="d-flex justify-content-center">
            <img
              style={{ height: height / 10 + "px", width: height / 10 + "px" }}
              className=""
              src="/images/cricket/bat.png"
              alt="bat"
            />
            <div className="align-self-center">
              <p style={{ fontSize: height / 40 + "px", fontWeight: 700 }}>
                MAXWELL
              </p>
              <p style={{ fontSize: height / 50 + "px" }}>10 (5)</p>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="d-flex justify-content-center">
            <img
              style={{
                height: height / 10 + "px",
                width: height / 10 + "px",
                marginRight: "5%",
              }}
              className=""
              src="/images/cricket/ball.png"
              alt="ball"
            />
            <div className="align-self-center">
              <p style={{ fontSize: height / 40 + "px", fontWeight: 700 }}>
                JADEJA
              </p>
              <p style={{ fontSize: height / 50 + "px" }}>1/29 (3.5)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portrait;
