import React, { Fragment, PureComponent } from "react";
import ReactGoogleSlides from "react-google-slides";

export default class GoogleSlides extends PureComponent {
  state = {
    date: new Date(),
    isInternetConnection: navigator.onLine,
  };

  componentDidMount = () => {
    setInterval(() => {
      this.setState({
        isInternetConnection: navigator.onLine,
      });
    }, 2000);
  };

  validateSlideUrl = (url) => {
    // check if it is a valid google slides url
    const regex =
      /^(https?:\/\/)?(www\.)?(docs|presentation)\.google\.com\/(presentation|docs)\/d\/([a-zA-Z0-9-_]+)\/?/;
    return regex.test(url);
  };

  render() {
    const { widget } = this.props;

    return this.validateSlideUrl(widget.data.url) ? (
      <Fragment>
        {this.state.isInternetConnection ? (
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{
              height: "100vh",
            }}
          >
            <ReactGoogleSlides
              slidesLink={widget.data.url}
              slideDuration={widget.data.slideDuration}
              position={1}
              loop
              width={window.innerWidth}
              height={window.innerHeight}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh",
            }}
          >
            <img src="/images/no-connection.png" alt="no-internet" />
          </div>
        )}
      </Fragment>
    ) : (
      ""
    );
  }
}
