import { useState, useEffect } from "react";

import Portrait from "./components/Portrait";
import Landscape from "./components/Landscape";
import Footer from "./components/Footer";

const Index = ({ widget }) => {
  const [displayType, setDisplayType] = useState("landscape");
  const width = window.innerWidth;
  const height = window.innerHeight;

  const { cryptosData, data } = widget;
  const currency = data?.currency;

  useEffect(() => {
    if (height > width) setDisplayType("portrait");
    if (width > height && width < height * 2) setDisplayType("landscape");
    if (width >= 4.5 * height) setDisplayType("footer");
  }, [height, width, setDisplayType]);

  return (
    <>
      {displayType}
      {displayType === "landscape" && (
        <Landscape currency={currency} cryptosData={cryptosData} />
      )}
      {displayType === "portrait" && (
        <Portrait currency={currency} cryptosData={cryptosData} />
      )}
      {displayType === "footer" && (
        <Footer currency={currency} cryptosData={cryptosData} />
      )}
    </>
  );
};

export default Index;
