const Footer = () => {
  const width = window.innerWidth;

  return (
    <div
      style={{ background: "#3c9ffd", height: "100vh" }}
      className="d-flex flex-column justify-content-center"
    >
      <div className="row align-items-center">
        <div className="col-md-2">
          <div className="d-flex align-items-center flex-column">
            <p style={{ fontSize: width / 70 }}>IND</p>
            <p style={{ fontWeight: 800, fontSize: width / 45 }}>180-3</p>
            <p style={{ fontSize: width / 80 }}>20.0 over | RR: 9.0</p>
          </div>
        </div>

        <div className="col-md-3">
          <div className="d-flex align-items-center justify-content-between">
            <img
              style={{
                height: width / 15 + "px",
                width: width / 15 + "px",
              }}
              src="/images/cricket/India.png"
              alt="india"
            />
            <p
              className="my-auto d-flex align-items-center justify-content-center"
              style={{
                height: width / 35 + "px",
                width: width / 35 + "px",
                borderRadius: "50%",
                background: "blue",
                fontWeight: 700, // dynamic
                fontSize: width / 60 + "px",
              }}
            >
              vs
            </p>
            <img
              style={{
                height: width / 15 + "px",
                width: width / 15 + "px",
              }}
              src="/images/cricket/Australia.png"
              alt="aus"
            />
          </div>
        </div>

        <div className="col-md-2">
          <div className="d-flex flex-column align-items-center ">
            <p style={{ fontSize: width / 70 }}>AUS</p>
            <p style={{ fontWeight: 800, fontSize: width / 45 }}>60-3</p>
            <p style={{ fontSize: width / 80 }}>10.0 over | RR: 6.0</p>
          </div>
        </div>

        <div className="col-md-5 text-center">
          <p style={{ fontSize: width / 70 + "px" }}>Match 1, Eden Garden</p>
          <p
            style={{
              fontSize: width / 60 + "px",
              fontWeight: 700,
            }}
          >
            Australia needs 120 runs from 60 balls to win
          </p>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex">
                <img
                  src="/images/cricket/bat.png"
                  style={{
                    height: width / 25 + "px",
                    width: width / 25 + "px",
                  }}
                  alt="bat"
                />
                <div>
                  <p style={{ fontSize: width / 80 + "px", fontWeight: 700 }}>
                    CUMMINS
                  </p>
                  <p className="not-out">25 (10)</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex">
                <img
                  src="/images/cricket/bat.png"
                  alt="bat"
                  style={{
                    height: width / 25 + "px",
                    width: width / 25 + "px",
                  }}
                />
                <div>
                  <p style={{ fontSize: width / 80 + "px", fontWeight: 700 }}>
                    MAXWELL
                  </p>
                  <p className="">10 (5)</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex">
                <img
                  style={{
                    height: width / 25 + "px",
                    width: width / 25 + "px",
                    marginRight: "5%",
                  }}
                  src="/images/cricket/ball.png"
                  alt="ball"
                />
                <div>
                  <p style={{ fontSize: width / 80 + "px", fontWeight: 700 }}>
                    JADEJA
                  </p>
                  <p>1/29 (5.5)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
